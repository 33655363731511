@if (this._timelineSpec() != null) {
  <div class="flex flex-col h-full w-full">
    <div class="flex flex-col w-full h-full" #zoombox>
      <div class="px-2 w-full z-10">
        <div class="w-full flex items-end h-5 mt-5 relative select-none">
          <div class="flex shrink-0" [style.width.%]="startPadding()"></div>
          @for (tick of ticksList(); track tick; let i = $index) {
            <div
              class="mt-5 relative border-l box-border shrink-0 border-gray-300 select-none"
              (click)="setCursorTime((i + startInt()) / ticksPerSec(), $event)"
              [style.height.%]="
                (i + startInt()) % ticksPerSec() === 0 ? 100 : 70
              "
              [style.width.%]="i === ticksInt() ? 0 : tickDist()"
              [ngClass]="{
                hidden:
                  tickDist() * ticksInt() + startPadding() > 100 &&
                  i === ticksInt(),
              }"
            >
              @if ((i + startInt()) % ticksPerSec() === 0) {
                <div
                  class="absolute text-sm -top-4 text-gray-500 -translate-x-1/2 cursor-default pointer-events-none"
                >
                  {{ (i + startInt()) / ticksPerSec() }}
                </div>
              }
            </div>
          }
          <div
            class="absolute -top-5 flex flex-col -translate-x-1/2 items-center z-[100]"
            [ngClass]="{ hidden: !showCursor() }"
            [style.left.%]="cursor_rel()"
          >
            <div
              class="rounded-full bg-primary-500 h-4 w-4 cursor-grab mt-1"
              (mousedown)="beginResize('c', $event)"
            ></div>
            <div class="flex relative" (mousedown)="beginResize('c', $event)">
              <div
                class="absolute h-1 w-2 bg-primary-500 rotate-[60deg] -right-[1px] -top-[4px]"
              ></div>
              <div
                class="absolute h-1 w-2 bg-primary-500 -rotate-[60deg] -left-[1px] -top-[4px]"
              ></div>
              <div
                class="absolute h-1 w-2 bg-primary-500 rotate-[79deg] -right-[3px] top-0"
              ></div>
              <div
                class="absolute h-1 w-2 bg-primary-500 -rotate-[79deg] -left-[3px] top-0"
              ></div>
            </div>
            <div
              class="absolute h-screen w-[4px] bg-primary-500 top-5 z-[100] pointer-events-none"
            ></div>
          </div>
        </div>
      </div>
      <div class="w-full trackbox select-none pt-2 px-2 relative">
        <div
          class="flex flex-col h-full w-full relative overflow-hidden bg-gray-100"
          (mouseenter)="checkShowScroll()"
          (mouseover)="checkShowScroll()"
          (mouseleave)="
            showHorizontalScroll = false; showVerticalScroll = false
          "
          #trackview
        >
          <div
            class="absolute right-1 w-2 z-20 bg-primary rounded-full transition-opacity delay-50"
            [ngClass]="{
              'opacity-0': !showVerticalScroll,
              'opacity-70': showVerticalScroll,
            }"
            [style.top.%]="scrollPos().top * (99.5 / 100) + 0.25"
            [style.height.%]="scrollPos().height * (99.5 / 100)"
          ></div>
          <div
            class="absolute bottom-1 h-2 z-20 bg-primary rounded-full transition-opacity delay-50"
            [ngClass]="{
              'opacity-0': !showHorizontalScroll,
              'opacity-70': showHorizontalScroll,
            }"
            [style.left.%]="left() * (99.5 / 100) + 0.25"
            [style.width.%]="width() * (99.5 / 100)"
            (mousedown)="beginResize('m', $event)"
          ></div>
          <div
            class="flex h-full w-full relative flex-col"
            [style.transform]="'translateY(' + translate() + 'px)'"
            (wheel)="$event.preventDefault(); scroll($event)"
          >
            @if (this.selected().length > 0) {
              <div
                class="absolute w-[2px] -translate-x-[1px] bg-primary z-20"
                [style.height.px]="trackBoxHeight()"
                [style.left.%]="selected()[0].rel.left"
                [ngClass]="{ hidden: !alignLeft() }"
              ></div>
              <div
                class="absolute w-[2px] translate-x-[1px] bg-primary z-20"
                [style.height.px]="trackBoxHeight()"
                [style.right.%]="
                  100 - (selected()[0].rel.left + selected()[0].rel.width)
                "
                [ngClass]="{ hidden: !alignRight() }"
              ></div>
            }

            <div class="flex flex-col" #track>
              @for (group of groupedBlocks(); track group.track) {
                <div
                  class="absolute h-full top-0 z-9 bg-gray-200"
                  [ngClass]="{
                    'bg-transparent':
                      trackLen() - (100 * this.left()) / this.width() <= 0,
                    'bg-gray-200':
                      trackLen() - (100 * this.left()) / this.width() > 0,
                  }"
                  [style.width.%]="
                    trackLen() - (100 * this.left()) / this.width()
                  "
                  [style.left.%]="0"
                  [style.transform]="'translateY(' + -translate() + 'px)'"
                ></div>
                @for (block of group.blocks; track block.id; let i = $index) {
                  <div
                    class="h-10 flex items-center justify-center relative"
                    [ngClass]="{
                      'mt-7': group.track !== group.blocks[0].id && i === 0,
                      'mb-1':
                        group.track !== group.blocks[0].id &&
                        i === group.blocks.length - 1,
                    }"
                  >
                    <div
                      class="absolute h-full w-full top-0 z-10"
                      (click)="
                        deselect.emit(); resetSelection(); updateBalloon()
                      "
                    ></div>

                    @if (i === 0 && group.track !== group.blocks[0].id) {
                      @if (this.blockMoving() && isSelected()[group.track]) {
                        <div
                          class="absolute border-box border-2 border-dotted bg-gray-400 border-gray-500 z-30 rounded-md pointer-events-none"
                          [style.width.%]="group.box.width"
                          [style.left.%]="group.box.left"
                          [style.height.rem]="group.blocks.length * 2.5 + 1.5"
                          [style.top.rem]="-1.5"
                        ></div>
                      }
                      <div
                        [class.currentGroup]="isSelected()[group.track]"
                        class="absolute ring-inset flex flex-col justify-between ring-[3px] rounded-md pointer-events-none"
                        [ngClass]="{
                          'ring-gray-400': !isSelected()[group.track],
                          'ring-primary': isSelected()[group.track],
                        }"
                        [style.width.%]="group.box.width"
                        [style.left.%]="group.box.left"
                        [style.height.rem]="group.blocks.length * 2.5 + 1.5"
                        [style.top.rem]="-1.5"
                        [style.z-index]="isSelected()[group.track] ? 31 : 11"
                        [style.transform]="
                          isSelected()[group.track] && this.blockMoving()
                            ? 'translateY(' +
                              transformBlock().y +
                              'px' +
                              ') scale(0.99)'
                            : ''
                        "
                      >
                        <div
                          class="h-6 w-full flex items-center justify-center rounded-t-md pointer-events-auto text-center text-white cursor-pointer"
                          [ngClass]="{
                            'bg-gray-400':
                              !isSelected()[group.track] &&
                              isHovered() !== group.track,
                            'bg-gray-500':
                              !isSelected()[group.track] &&
                              isHovered() === group.track,
                            'bg-primary': isSelected()[group.track],
                          }"
                          (mousedown)="
                            setSelect(group.track, $event, 'group');
                            beginResize('mb', $event)
                          "
                          (mouseenter)="hover(group.track)"
                          (mouseleave)="
                            !(
                              isSelected()[group.track] && this.blockMoving()
                            ) && hover(null)
                          "
                          (contextmenu)="
                            setSelect(group.track, $event, 'group');
                            context.emit({
                              event: $event,
                              block: this.selectGroupIdent(),
                            })
                          "
                        >
                          <confect-icon
                            icon="folder_outlined"
                            iconClass="text-xl mr-1"
                            >{{ group.trackName }}
                          </confect-icon>
                        </div>
                      </div>
                    }
                    @if (this.blockMoving() && isSelected()[block.id]) {
                      <div
                        class="flex h-full top-0 items-center absolute py-[1px]"
                        [style.width.%]="block.width"
                        [style.height.%]="block.height"
                        [style.left.%]="block.left"
                        [style.z-index]="29"
                      >
                        <div
                          class="rounded-md h-full w-full bg-gray-400 border-2 border-box border-dotted border-gray-500"
                        ></div>
                      </div>
                    }

                    <div
                      class="flex h-full top-0 items-center absolute py-[1px]"
                      [style.width.%]="block.width"
                      [style.height.%]="block.height"
                      [style.left.%]="block.left"
                      [style.z-index]="
                        isSelected()[block.id] || isSelected()[group.track]
                          ? 30
                          : 10
                      "
                      [style.transform]="
                        (isSelected()[block.id] || isSelected()[group.track]) &&
                        this.blockMoving()
                          ? 'translateY(' +
                            transformBlock().y +
                            'px' +
                            ') scale(0.99)'
                          : ''
                      "
                      [class.currentBlock]="isSelected()[block.id]"
                      [attr.id]="isSelected()[block.id] ? 'current' : ''"
                    >
                      <ngx-timeline-block
                        #block
                        class="flex flex-grow h-full"
                        (resizMove)="
                          setSelect(block.id, $event.event, 'block');
                          beginResize($event.dir, $event.event)
                        "
                        (context)="context.emit($event)"
                        [current]="isSelected()[block.id]"
                        [grab]="isSelected()[block.id] && blockMoving()"
                        [layerInfo]="block"
                        [duration]="(block.width / 100) * (total() / 2)"
                        (layerHover)="hover($event)"
                        [hovered]="isHovered() === block.id"
                      />
                    </div>
                  </div>
                }
              }

              @for (
                emptyTrack of emptyTracks();
                track emptyTrack;
                let i = $index
              ) {
                <div
                  class="flex items-center justify-center relative overflow-visible"
                  [style.height.px]="emptyTrack"
                >
                  <div
                    class="absolute h-full w-full top-0 z-10"
                    (click)="deselect.emit(); updateBalloon()"
                  ></div>
                </div>
              }
            </div>

            <div
              class="absolute flex h-full w-full overflow-visible"
              [style.transform]="'translateY(' + -translate() + 'px)'"
            >
              <div
                class="flex h-full shrink-0"
                [style.width.%]="trackStartPadding()"
              ></div>
              @for (second of secondsList(); track second; let i = $index) {
                <div
                  class="h-full border-x border-white opacity-50 shrink-0 flex box-border"
                  [style.width.%]="
                    i === secondsInt() ? 0 : tickDist() * ticksPerSec()
                  "
                ></div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}
<ngx-balloon-menu
  [parent]="currentBlockElement"
  [show]="
    selectIdent != null &&
    !this.blockMoving() &&
    !scrolling &&
    this.multiSelectIdent().size === 0
  "
>
  <div class="flex">
    <confect-icon-button
      (click)="settings.emit()"
      icon="settings_outlined"
      class="px-2 py-1"
      iconClass="text-lg hover:text-primary"
      tooltip
      tooltipText="Layer Settings"
      tooltipPosition="above"
    />
  </div>
  <div class="flex">
    <confect-icon-button
      (click)="effect.emit('effect_in')"
      class="px-2 py-1"
      iconClass="text-lg hover:text-primary"
      icon="keyframe_linear_in"
      tooltip
      tooltipText="Add intro effect"
      tooltipPosition="above"
    />
    <!-- <confect-icon-button
      class="p-2"
      iconClass="text-lg hover:text-primary"
      icon="photo_filter"
      tooltip
      tooltipText="Add middle effect"
      tooltipPosition="above"
    ></confect-icon-button> -->
    <confect-icon-button
      (click)="effect.emit('effect_out')"
      class="px-2 py-1"
      iconClass="text-lg hover:text-primary"
      icon="keyframe_linear_out"
      tooltip
      tooltipText="Add outro effect"
      tooltipPosition="above"
    />
  </div>
</ngx-balloon-menu>
