import { Location } from "@angular/common";
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { ElementOverviewSection } from "@core/api-models/shared-elements.models";
import { DesignsService } from "@core/api/designs.service";
import {
  Creative,
  CreativeEditorMode,
  CreativePreviewSettings,
} from "@core/models/creative.types";
import { AccessService } from "@core/services/access.service";
import { CreativesEditService } from "@core/services/creatives/creatives-edit.service";
import {
  CreativeLiveServiceConfig,
  CreativesLiveService,
} from "@core/services/creatives/creatives-live.service";
import { effects } from "@core/services/creatives/preset-effects";
import { CustomFieldsService } from "@core/services/custom-fields.service";
import { HomeActionsService } from "@core/services/home-actions-service";
import { HotjarService } from "@core/services/hotjar.service";
import { ProctorService } from "@core/services/proctor.service";
import { UserService } from "@core/services/user.service";

import { CSlideOverComponent } from "@theme/@confect/components/slide-over/slide-over.component";
import { DialogBoxTransmitterService } from "@theme/@confect/services/confect-dialog-box-transmitter.service";
import { CPopupModalService } from "@theme/@confect/services/confect-popup-modal.service";

import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { CreativesEditEditorComponent } from "./components/creatives-edit-editor/creatives-edit-editor.component";
import { resolutions } from "./creatives-resolutions";
import { CreativeError } from "./types/creative-error";

@Component({
  selector: "ngx-creatives-edit",
  templateUrl: "./creatives-edit-v3.component.html",
  styleUrls: ["./creatives-edit.component.scss"],
  standalone: false,
})
export class CreativesEditComponent implements OnInit, OnDestroy {
  public readonly CreativeEditorMode = CreativeEditorMode;

  @ViewChild("autoEffectsSlideover", { static: true })
  autoEffectsSlideover: CSlideOverComponent;

  autoEffects = effects;

  // Id of creative we are editing
  id = null;

  introFinished: boolean = true;

  // Sidebar content
  specs: any = {};

  // Creative spec editor instance
  editor: CreativesEditService = null;
  live: CreativesLiveService = null;

  // Video render flags
  resolutions = resolutions;
  objectKeys = Object.keys;
  resOptions = this.objectKeys(this.resolutions);
  selectedResolution = "1:1";
  videoSupport = false;
  selectedQuality = 4;

  outerCanvas = 9;

  // State flags
  isSaving = false;

  // Recipe callback when we wish to go to recipes after save
  recipeCreateCallback = null;

  // Extra settings
  extraSettings = { minimalUser: true };
  helpEnabled = true;

  // Video loading / preview
  currentProgress = 0;
  videoURL = null;

  // Preview filter
  selectedPreviewFilters = {};
  previewFilterOptions = [];

  // Layer preview stuff
  layerImages = [];
  isRefreshingLayers = false;
  layerRefreshTask = null;

  editorMode: CreativeEditorMode = CreativeEditorMode.IMAGE;

  raisedError: CreativeError = null;
  showSidebar = false;

  hasUnsavedChanges = false;

  /* Canvas */

  gridPosition = {
    left: 0,
    top: 0,
  };

  gridToolbarPosition = {
    left: 0,
    top: 0,
  };

  movePos: MousePosition = null;

  design: Creative;

  @ViewChild("gridToolbar") gridToolbar: ElementRef;
  @ViewChild("editorComponent") editorRef: CreativesEditEditorComponent;
  @ViewChild("previewDialog") previewDialog: TemplateRef<any>;

  /* End canvas */

  // Magic Effects
  selectedEffect = null;

  unsubscribe$ = new Subject();

  demoMode = false;
  demoModePreviewClicked = false;

  elementsOverview: ElementOverviewSection[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private designsService: DesignsService,
    private proctor: ProctorService,
    private accessService: AccessService,
    private ref: ChangeDetectorRef,
    private fs: CustomFieldsService,
    private us: UserService,
    private ps: CPopupModalService,
    private ha: HomeActionsService,
    private hj: HotjarService,
    private dialogTransmitter: DialogBoxTransmitterService,
  ) {
    // For navigation from creative automation
    // const spec: CreativeSpec = this.router.getCurrentNavigation().extras.state as CreativeSpec;
    // if (spec != null) {
    //   this.loadEditorService({
    //     name: "",
    //     spec: { sequences: spec.sections },
    //   });
    // }

    this.introFinished =
      localStorage.getItem("designIntroShown") === "YES" ||
      (this.accessService.current?.account.state.has_designs ?? true);

    localStorage.setItem("designIntroShown", "YES");
  }

  // Init
  ngOnInit() {
    this.route.data.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.initializeEditor();
    });

    this.us.me(false).subscribe((me) => {
      this.demoMode = me.anon === true;
    });
  }

  ngOnDestroy() {
    this.live?.destroy();
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  loadEditorService(creative: Creative = null) {
    const ps: CreativePreviewSettings = creative
      ? creative.preview_settings
      : {};

    // Saved preview filters
    const f = ps.preview_filters;
    this.selectedPreviewFilters = f ? f : {};

    // Optionally get stuff from state?

    const state = window.history.state;
    const routerSpec = state?.spec ?? null;
    const resolutionState = state?.resolution ?? null;

    // Saved editor mode
    const editorMode = ps.editorMode;
    if (editorMode) {
      this.editorMode = editorMode;
    }

    // Saved resolution/aspect
    let selRes = ps.selectedResolution;
    let res = ps.resolution;

    if (!creative && resolutionState) {
      selRes = "custom";
      res = resolutionState;
    }

    if (selRes && res) {
      this.selectedResolution = selRes;

      if (selRes === "custom") {
        this.resolutions["custom"].size = res;
      }

      setTimeout(() => {
        this.editorRef.configureResolution();
      });
    }

    this.hj.event(
      this.editorMode === CreativeEditorMode.VIDEO
        ? "video_editor_loaded"
        : "image_editor_loaded",
    );

    // Live images cache
    const cachedImages = creative ? creative.live_image_cache : {};

    const spec = creative?.spec ?? routerSpec;

    this.editor = new CreativesEditService(
      spec,
      creative?.name,
      this.accessService.current.account.has_feed,
    );

    const company = this.accessService.current.company.id;
    const account = this.accessService.current.account.id;

    // We need to configure our live service
    const liveConfig: CreativeLiveServiceConfig = {
      editor: this.editor,
      company: company,
      account: account,
      proctor: this.proctor.info.proctorModeActive()
        ? this.proctor.info.currentProctorCompany.id
        : null,
      previewFilters: this.selectedPreviewFilters,
      resolution: this.editorRef?.currentLiveResolution ?? [500, 500],
    };

    // Activate live mode for the editor
    this.live = this.editor.activateLiveMode(liveConfig);
    this.live.checkLayers();

    // Load cached images
    if (cachedImages) {
      this.live.loadInitialImages(cachedImages);
    }

    this.setupCallbacks();
  }

  initializeEditor() {
    this.loadEditorSettings();
    this.loadPreviewFilterOptions();

    // Feature enables
    if (this.accessService.hasFeature("video")) {
      this.extraSettings.minimalUser = false;
      this.videoSupport = true;
      // this.editorMode = "video";
    }

    // Video spec load
    this.designsService
      .getVideoSpecs()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((specs) => {
        this.specs = specs;
      });

    // Load creative if id given
    this.route.paramMap.pipe(takeUntil(this.unsubscribe$)).subscribe((pm) => {
      const id = pm.get("id");

      // Nothing to load
      if (id == null) {
        // Load empty editor if needed
        if (!this.editor) {
          this.loadEditorService(null);
        }

        // Check if recipe callback exists
        this.checkRecipeCallback();
        return;
      }

      // Load creative from API
      this.id = id;
      this.designsService
        .getCreatives(this.id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (res) => {
            this.loadEditorService(res);
            this.design = res;
            // this.refreshLayerPreview();
          },
          error: (_err) => {
            this.router.navigate(["/designs"]);
          },
        });
    });
  }

  back() {
    this.router.navigate(["/designs"]);
  }

  // Hooking up event changes when creative is edited
  setupCallbacks() {
    this.editor.gridChanged$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((change) => {
        this.gridChanged();
      });

    // this.editor.cellChanged$
    //   .pipe(takeUntil(this.unsubscribe$))
    //   .subscribe((cell) => {
    //     this.cellChanged(cell);
    //   });

    this.editor.layerChanged$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((layer) => {
        // Do a change detection on layer change, to make sure all settings and defaults sync
        this.ref.detectChanges();
      });

    // Live on backend raises error
    this.live.errorRaised$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((error) => {
        this.raisedError = error;
      });

    this.editor.history.createdHistoryPoint$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((_) => {
        this.hasUnsavedChanges = true;
      });

    // Make sure we detect initial cell
    // this.cellChanged(this.editor.cell);
  }

  // // Selected cell changed to something else
  // cellChanged(c: CreativeCell) {
  //   // No cell selected
  //   if (c == null) {
  //     this.activeSelectionType = null;
  //     return;
  //   }

  //   // Cell without a type
  //   if (c.type === "") {
  //     this.activeSelectionType = null;
  //     return;
  //   }

  //   // Cell with a type
  //   this.activeSelectionType = c.type.toUpperCase();
  // }

  // The grid changed / was sliced in some way
  gridChanged() {
    // this.activeSelectionType = null;
    // this.editor.deselectCell();
  }

  // Product preview filters
  loadPreviewFilterOptions() {
    this.fs
      .getFields()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.previewFilterOptions = res;
      });
  }

  // Help settings / flag change
  helpChanged(to: boolean) {
    this.helpEnabled = to;
    localStorage["editorHelpEnabled"] = to;
  }

  // Load user-specific editor settings
  loadEditorSettings() {
    const h = localStorage["editorHelpEnabled"];
    if (h === undefined) {
      return;
    }
    this.helpEnabled = h === "true";
  }

  // Check if we have a recipe callback as query param and set it
  checkRecipeCallback() {
    const qpm = this.route.snapshot.queryParamMap;
    if (qpm.has("recipeCallback")) {
      this.recipeCreateCallback = qpm.get("recipeCallback");
    }
  }

  // Save creative
  async save(callback = false) {
    if (this.editor.name === "") {
      return;
    }

    const spec = this.editor.getCleanSpec();

    const creative: Creative = {
      name: this.editor.name,
      spec: spec,
      preview_settings: {
        preview_filters: this.selectedPreviewFilters,
        selectedResolution: this.selectedResolution,
        resolution: this.editorRef.currentPreviewResolution,
        editorMode: this.editorMode,
      },
    };

    if (this.id !== null) {
      creative["id"] = this.id;
    }

    this.designsService
      .saveCreative(creative)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (res) => {
          // Triggering a home update here, should be implemented later as a global event service
          this.ha.getData(true).subscribe();

          let created = false;
          if (this.id === null) {
            created = true;
            const url = this.router
              .createUrlTree([res["id"]], { relativeTo: this.route })
              .toString();

            this.location.replaceState(url);
            this.id = res["id"];
          }

          if (callback && this.recipeCreateCallback !== null) {
            this.router.navigate(
              ["automations", "create", this.recipeCreateCallback],
              { queryParams: { selectCreativeId: this.id } },
            );
          } else {
            this.hasUnsavedChanges = false;

            this.ps.success({
              title: "Saved!",
              autoCloseTimeout: 1500,
            });
            setTimeout(() => {
              this.validateVideo();
            }, 1500);
          }
        },
        error: (err) => {},
        complete: () => {},
      });
  }

  close() {
    if (this.demoMode) {
      this.router.navigate(["/designs/demo"]);
      return;
    }

    if (!this.hasUnsavedChanges) {
      this.router.navigateByUrl(
        this.design != null
          ? `/designs?folder_id=${this.design.ui_folder}`
          : "/designs",
      );
      return;
    }

    this.ps
      .warning({
        title: "You have unsaved changes",
        confirmText: "Close Anyway",
        cancelText: "Keep Editing",
      })
      .outputs["modalClosed"].asObservable()
      .subscribe({
        next: (res) => {
          if (res) {
            this.router.navigateByUrl(
              this.design != null
                ? `/designs?folder_id=${this.design.ui_folder}`
                : "/designs",
            );
          }
        },
      });
  }

  // Observable for rendering a video job with progres
  renderJobObservable(jobID) {
    return new Observable((observer) => {
      const action = setInterval(() => {
        this.designsService
          .getVideoStatus(jobID)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((res) => {
            this.currentProgress = res.progress;
            if (res.status === "finished") {
              observer.next(res.result.url);
              clearInterval(action);
              observer.complete();
            } else if (res.status === "failed") {
              observer.error(res.error);
              clearInterval(action);
              observer.complete();
            }
          });
      }, 1000);
    });
  }

  validateVideo() {
    this.raisedError = null;

    const spec = this.editor.getSpec();
    const size = [50, 50];

    this.designsService
      .enqueueVideoDemo(spec, size, false, this.selectedPreviewFilters)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res1) => {
        this.renderJobObservable(res1.job_id)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe({
            next: (_) => {},
            error: (error) => {
              this.raisedError = error;

              this.ps.error({
                title: "Saved with error",
                text: error.message,
                autoCloseTimeout: 4000,
              });
            },
          });
      });
  }

  showAutoEffects() {
    this.autoEffectsSlideover.expand();
  }

  freeTrial() {
    window.open("https://confect.io/start-free-trial/", "_blank");
  }
  endIntro(event: MouseEvent) {
    this.introFinished = true;
    this.dialogTransmitter.transmit({
      id: "preview",
      template: this.previewDialog,
      event: event,
      // postEvent: () => {
      //   this.dialogTransmitter.transmit({
      //     id: "elements",
      //     template: this.previewDialog,
      //   });
      // },
    });
  }
}

interface MousePosition {
  top: number;
  left: number;
  x: number;
  y: number;
}
