import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";

import { MediaService } from "@core/api/media.service";
import {
  CreativeCellType,
  CreativeLayer,
  CreativeLayerType,
} from "@core/models/creative.types";
import { MediaLibraryItem } from "@core/models/media-library.types";
import { CreativesEditService } from "@core/services/creatives/creatives-edit.service";
import { CreativesLiveService } from "@core/services/creatives/creatives-live.service";

import { MediaOverviewComponent } from "@components/designs/media-overview/media-overview.component";
import { EffectsComponent } from "@components/effects/effects/effects.component";
import { TimelineBlockSpec } from "@components/timeline/timeline/timeline.component";

import { ModalRef } from "@theme/@confect/models/modal.types";
import { CPopupModalService } from "@theme/@confect/services/confect-popup-modal.service";

import { SharedElementsComponent } from "../shared-elements/shared-elements.component";

export interface CellTypeDisplay {
  name: string;
  type: CreativeCellType;
  icon: string;
}

interface SideInterface {
  offset: number;
  nearPoint?: number;
  diffOffset?: number;
}

@Component({
  selector: "ngx-creatives-edit-timeline",
  templateUrl: "./creatives-edit-timeline.component.html",
  styleUrls: ["./creatives-edit-timeline.component.scss"],
  standalone: false,
})
export class CreativesEditTimelineComponent implements OnInit {
  private readonly MINIMUM_DURATION_FOR_STICKY = 0.2;
  private readonly MAX_CURSOR_DISTANCE_FOR_UNSTICKY = 10;

  @Input() specs: any;
  _editor: CreativesEditService;
  @Input() live: CreativesLiveService;
  @Input() set editor(to: CreativesEditService) {
    if (to == null) {
      return;
    }
    this._editor = to;
    this.timelineSpecs = this.layerTimings();
  }
  get editor() {
    return this._editor;
  }

  @Input() showTimeline = true;

  @Input() raisedError: any = {};

  @Input() allowProductActions = true;

  @Input() allowDeselect = true;

  @Output() context = new EventEmitter();
  @Output() save = new EventEmitter();

  @ViewChild("timeCol", { static: true }) timeCol: ElementRef;

  @ViewChild("layerAdd", { read: TemplateRef, static: true })
  templateLayerAdd: TemplateRef<any>;

  @ViewChild("sharedElementsTemplate", { static: true })
  sharedElementsTemplate: TemplateRef<SharedElementsComponent>;

  @ViewChild("mediaLibrary", { static: true })
  mediaLibrary: TemplateRef<any>;
  @ViewChild("overview") overview: MediaOverviewComponent;

  @ViewChild("effectAdd") effect: TemplateRef<EffectsComponent>;
  @ViewChild("settings") settings: TemplateRef<any>;

  // The padding in each side of the time col
  TIME_COL_PADDING = 15;

  timelineDuration = 10;

  timelineSpecs: TimelineBlockSpec[];

  // Resize handling
  resizeDirection = null;
  resizeElement = null;
  positionInElement = null;
  resizeLayer: CreativeLayer = null;

  selectedLayers: string[] = [];

  effectSelected;

  cellTypes: CellTypeDisplay[] = [
    {
      name: "Product",
      type: CreativeCellType.PRODUCT,
      icon: "label_outlined",
    },
    {
      name: "Text",
      type: CreativeCellType.TEXT,
      icon: "text",
    },
    {
      name: "Media",
      type: CreativeCellType.MEDIA,

      icon: "photo_landscape_outlined",
    },
    {
      name: "Shape",
      type: CreativeCellType.SHAPE,

      icon: "circle_outlined",
    },
    {
      name: "Asset",
      type: CreativeCellType.PRODUCT_ASSET,

      icon: "blend_tool",
    },
  ];

  cursorPosition = 0;
  cursorDelta = 0;
  isSticky = false;
  pointX = 0;
  offsetsCoordinates = [0];

  mediaModal: ModalRef;

  ngOnInit(): void {
    this.editor.layerChanged$.subscribe((_) => {
      this.timelineSpecs = this.layerTimings();
      this.selectedLayers =
        this.editor.selectedLayerIdentifier != null
          ? [this.editor.selectedLayerIdentifier]
          : this.editor.multiSelectedLayerIdentifiers.size > 0
            ? Array.from(this.editor.multiSelectedLayerIdentifiers)
            : [];
    });

    this.editor.layerAddRemove$.subscribe((_) => {
      this.timelineSpecs = this.layerTimings();
    });
  }

  timeColWidth() {
    // Actual space/size of the time column without padding included
    const paddings = this.TIME_COL_PADDING * 2;
    return this.timeCol.nativeElement.getBoundingClientRect().width - paddings;
  }

  commitLayerTimes(blocks: TimelineBlockSpec[]) {
    blocks.forEach((block) => {
      this.editor.updateLayerTimes(block.id, block.start, block.duration);
    });
  }

  layerTimings() {
    // if (this.specs.LAYER == null) {
    //   return []
    // }

    // const defaultDuration = this.specs.LAYER[0].items.filter(a => a.name === 'total_duration')[0].default;
    const defaultDuration = 6;

    const layers = Array.from(this.editor.iterateLayers(false));

    // Calculate layer timings
    return layers.map((l) => {
      let duration = defaultDuration;
      let offset = 0;

      if (l.type === CreativeLayerType.LAYER) {
        if ("total_duration" in l.LAYER) {
          duration = parseFloat(l.LAYER["total_duration"] as string);
        }

        if ("layer_offset" in l.LAYER) {
          offset = parseFloat(l.LAYER["layer_offset"] as string);
        }
      }

      return {
        name: l.name,
        id: l.identifier,
        track: l.parent ?? l.identifier,
        trackName:
          l.parent != null ? this.editor.getLayer(l.parent).name : l.name,
        type: l.config.grid_config[0].type,
        start: offset,
        duration: duration,
        effect: l.config.grid_config[0].config,
      };
    });
  }

  getLayer(layerIdent: string): any {
    return this.editor.getLayer(layerIdent);
  }

  constructor(
    private popUpService: CPopupModalService,
    private mediaService: MediaService,
  ) {}

  track(index, item) {
    // Using trackBy for weights as they track an array of primitive types
    return index;
  }

  formatTimelineSecond(value: number) {
    return value + "s";
  }

  productRounds(layer): number {
    const rounds = layer.info.LAYER["product_rounds"];
    return rounds == null ? 0 : parseInt(rounds);
  }

  emptyArray(size): number[] {
    return new Array(size).fill(null).map((_, i) => i + 1);
  }

  productIndicatorLeft(s, i) {
    const r = `calc(${(100 / (s + 1)) * i}% - 1px)`;
    return r;
  }

  addLayer(cellType: CellTypeDisplay) {
    if (cellType.type === CreativeCellType.MEDIA) {
      this.mediaModal = this.popUpService.template({
        template: this.mediaLibrary,
        showClose: false,
      });
    } else {
      this.editor.addLayer(cellType.type);
    }
  }

  mediaLayerSelect(media: MediaLibraryItem) {
    // Run in another context to prevent hide lag
    setTimeout(() => {
      this.editor.addLayer(CreativeCellType.MEDIA);
      this.editor.layer.config.grid_config[0].config = {
        media_url: {
          id: media.id,
          name: media.name,
          source_key: `uploaded_${media.type}s`,
        },
      };
      this.editor.layerChangedSource.next(true);
    }, 50);
  }

  uploadMedia() {
    this.mediaService
      .uploadMediaFileInteractive(["image/*", "video/*"], "Media")
      .subscribe({
        next: (files: any) => {
          files.forEach((file) => {
            const folderDepth = this.overview.folders.items.length;
            const currentFolder =
              this.overview.folders.items[folderDepth - 1].id;
            this.overview.folders.moveItem(file.id, currentFolder, () => {
              const media = this.overview.getById(file.id);
              this.mediaLayerSelect(media);
              this.mediaModal.close();
            });
          });
        },
      });
  }

  selectedSharedElement(spec: CreativeLayer) {
    this.editor._migration__sliceToBoundBox(spec.layers);
    this.editor.addLayerSpec(spec);
  }

  addEffect() {
    this.popUpService
      .template({
        showClose: false,
        template: this.effect,
        disableScroll: true,
      })
      .outputs["modalClosed"].asObservable()
      .subscribe({
        next: (res) => {
          if (res) {
            this.save.emit();
          }
        },
      });
  }

  openSettings() {
    this.popUpService
      .template({
        showClose: false,
        template: this.settings,
      })
      .outputs["modalClosed"].asObservable()
      .subscribe({
        next: (res) => {},
      });
  }
  groupLayers(layerIDs: string[], dir: string) {
    const layers = layerIDs.map((id) => this._editor.getLayer(id));
    if (layers[1].type === "group") {
      return;
    }
    if (layers[0].type === "group") {
      this._editor.addToGroup(layers[1], layers[0], dir === "up" ? -1 : 0);
      return;
    }
    this._editor.addGroup(layers);
  }
}
