import { moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

import { CreativesEditService } from "@core/services/creatives/creatives-edit.service";

@Component({
  selector: "ngx-effects-multiple-spec",
  templateUrl: "./effects-multiple-spec.component.html",
  styleUrls: ["./effects-multiple-spec.component.scss"],
  standalone: false,
})
export class EffectsMultipleSpecComponent implements OnInit {
  _specItem: any = null;

  effectSpecs: any[][] = [];

  @Input()
  set specItem(specItem: any) {
    this._specItem = specItem;

    // // Support for effects with a default selection
    // setTimeout(() => {
    //   // If has a default selection, but no settings yet, make sure the object is set
    //   if (
    //     specItem.default.effect !== null &&
    //     !Object.keys(this.value.settings).length &&
    //     this.value.effect === specItem.default.effect // Only if saved effect is default as well
    //   ) {
    //     this.valueChange.emit(specItem.default.effect);
    //   }
    // }, 100);

    setTimeout(() => {
      this.updateEffectSpecs();
    });
  }

  @Input() value: any[] = [];

  @Input() editor: CreativesEditService;

  @Input() helpEnabled = true;

  @Output() valueChange = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  addEffect() {
    this.value.push({
      effect: null,
      settings: {},
      with_previous: false,
      duration: 1,
      offset: 0,
    });
  }

  duplicateEffect(effect) {
    // Deep copy
    const d = JSON.parse(JSON.stringify(effect));

    // Push and emit change
    this.value.push(d);
    this.valueChange.emit(this.value);
  }

  removeEffect(index) {
    this.value.splice(index, 1);
  }

  updateEffectSpecs() {
    if (!this._specItem) {
      this.effectSpecs = [];
      return;
    }

    // const n = this.value.effect;
    this.effectSpecs = this.value.map(
      (n) => this._specItem.data.find((s) => s.function === n.effect)?.spec,
    );
  }

  drop(event) {
    moveItemInArray(this.value, event.previousIndex, event.currentIndex);
    this.valueChange.emit(this.value);
  }

  effectChanged(effect, to) {
    effect.effect = to;
    effect.settings = {};

    this.valueChange.emit(this.value);

    setTimeout(() => {
      this.updateEffectSpecs();
    });
  }
}
