import { DragRef } from "@angular/cdk/drag-drop";
import { DOCUMENT } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  WritableSignal,
  Signal,
  computed,
  signal,
  ViewChild,
  ElementRef,
  HostListener,
  AfterViewInit,
  OnDestroy,
  inject,
  input,
} from "@angular/core";

import { MediaService } from "@core/api/media.service";
import {
  CreativeCellType,
  CreativeEditorMode,
  CreativeLayer,
} from "@core/models/creative.types";
import { UploadMediaResponseInterface } from "@core/models/upload-media";
import { CreativesEditService } from "@core/services/creatives/creatives-edit.service";
import { CreativesLiveService } from "@core/services/creatives/creatives-live.service";
import { ImageSpec } from "@core/services/creatives/creatives-socket.service";
import { iterateSpecificLayers } from "@core/services/creatives/layout-helpers";
import { Point, CMath } from "@core/utils/confect-math";
import { Box, CBoxHelper } from "@core/utils/confect-math";

import { BalloonMenuComponent } from "@theme/@confect/components/balloon-menu/balloon-menu.component";
import { CPopupModalService } from "@theme/@confect/services/confect-popup-modal.service";

import { Subject } from "rxjs";

export interface layerBox {
  layer: string;
  box: Box;
  img: ImageSpec | null;
  config: any;
  children?: string[];
}
export interface Image {
  x: number;
  y: number;
  width: number;
  height: number;
}
export interface ImageBox {
  layer: string;
  image: Image;
  css: string;
  config: any;
}

export interface Snap {
  left: boolean;
  right: boolean;
  top: boolean;
  bottom: boolean;
  middle: boolean;
  center: boolean;
}
export interface BoxDist {
  left: { left: number; center: number; right: number };
  right: { left: number; center: number; right: number };
  top: { top: number; middle: number; bottom: number };
  bottom: { top: number; middle: number; bottom: number };
  middle: { top: number; middle: number; bottom: number };
  center: { left: number; center: number; right: number };
}

export enum KEY_CODE {
  LEFT = "ArrowLeft",
  RIGHT = "ArrowRight",

  UP = "ArrowUp",
  DOWN = "ArrowDown",
}

@Component({
  selector: "ngx-creatives-edit-grid-v3",
  templateUrl: "./creatives-edit-grid-v3.component.html",
  styleUrl: "./creatives-edit-grid-v3.component.scss",
  standalone: false,
})
export class CreativesEditGridV3Component
  implements OnInit, AfterViewInit, OnDestroy
{
  _editor: CreativesEditService;
  @Input() set editor(to: CreativesEditService) {
    if (to == null) {
      return;
    }
    this._editor = to;
    this.buildBoxes();
  }
  _editorMode: CreativeEditorMode;
  @Input() set editorMode(to: CreativeEditorMode) {
    if (!to) {
      this._editorMode = CreativeEditorMode.IMAGE;
    }
    this._editorMode = to;
    this.setCanvas();
  }
  _live: CreativesLiveService;
  @Input() set live(to: CreativesLiveService) {
    if (to == null) {
      return;
    }
    this._live = to;
  }

  zoomFactor = input(1);
  allowSnap = input(true);

  _resolution = [500, 500];

  @Input()
  set resolution(to) {
    this._resolution = to;
    this.setCanvas();
    this._live.invalidateLayers();
  }

  @Input() aspect: string | [number, number] = "1:1";
  @Input() showBox: boolean = false;
  @Input() canvasScaling = 5;
  @Input() scrollOffset: { x: number; y: number } = { x: 0, y: 0 };

  @Input() set time(to: number) {
    if (to == null) {
      return;
    }
    this.cursorTime.update(() => to);
  }

  @Output() context = new EventEmitter();
  @Output() moveCanvas = new EventEmitter();
  @Output() downCanvas = new EventEmitter();

  @ViewChild("grid", { static: true }) grid: ElementRef;
  @ViewChild("innerCanvas", { static: true }) innerCanvas: ElementRef;
  @ViewChild("boxGrid", { static: true }) boxGrid: ElementRef;

  @ViewChild("balloon", { static: true }) balloon: BalloonMenuComponent;

  layerStack: string[] = [];

  editMode: WritableSignal<boolean> = signal(false);
  isHovering: WritableSignal<boolean> = signal(true);
  isEditing: WritableSignal<boolean> = signal(false);
  isResizing: WritableSignal<boolean> = signal(false);
  isSelecting: WritableSignal<boolean> = signal(false);

  shift = false;
  alt = false;

  showHoverState: Signal<boolean[]> = computed(() => {
    const boxes = this.boxes();
    return boxes.map((box, i) => {
      const isCurrLayer =
        box.layer === this._editor.layer?.identifier ||
        box.layer === "multiSelection";
      const isHoverBox = this.hoverBox() === box.layer;
      const isSnappedTo = this.highlightBoxes()[i];
      const isBeingSelected = this.selectHighlight()[i] && this.isSelecting();
      const isFocused = box.layer === this.focusBox()?.layer;
      const showChildren =
        this.showChildLayers()[i] && !this.isResizing() && !isHoverBox;
      const isChildOfCurr =
        this.currentLayer()?.children?.includes(box.layer) ||
        this.checkChildren(this.currentLayer()?.children ?? [], box.layer);

      const canBeHovered = !isCurrLayer || isFocused;
      const isSeperateSnap = isSnappedTo && !isChildOfCurr;
      const hideCurrChildren =
        this.focusBox()?.layer != null && isChildOfCurr && !isFocused;

      return (
        canBeHovered &&
        (isHoverBox || isSeperateSnap || showChildren || isBeingSelected) &&
        !hideCurrChildren
      );
    });
  });

  showGuidelines: boolean = true;
  allowStack: boolean = false;

  cursorTime: WritableSignal<number> = signal(0);
  showBoxes: Signal<boolean[]> = computed(() =>
    this.boxes().map((box) => {
      if (
        box.layer === "multiSelection" ||
        this._editorMode === CreativeEditorMode.IMAGE
      ) {
        return true;
      }
      const check = (layer) => {
        const start = parseFloat(layer.LAYER?.layer_offset ?? 0);

        const end =
          parseFloat(layer.LAYER?.layer_offset ?? 0) +
          parseFloat(layer.LAYER?.total_duration ?? 6);

        return start <= this.cursorTime() && end >= this.cursorTime();
      };
      const layer = this._editor.getLayer(box.layer);

      if (layer.type === "group") {
        return layer.layers.some((l) => check(l));
      }
      return check(layer);
    }),
  );

  hoverBox: WritableSignal<string | null> = signal(null);
  focusBox: WritableSignal<layerBox | null> = signal(null);
  layerShow: Signal<boolean> = computed(() => this.isHovering());
  movableLayers: Signal<Record<string, boolean>> = computed(() => {
    this.currentLayer(); //Makes the signal update when currentLayer changes
    this.currentImage();
    const layers = this._editor.layer
      ? [this._editor.layer]
      : this._editor.multiSelectedLayers();
    if (!layers) {
      return [];
    }

    const _layers = Array.from(iterateSpecificLayers(layers, true)).map(
      (layer) => layer.identifier,
    );

    const res = {};
    this.boxes().forEach((box) => {
      res[box.layer] = _layers.includes(box.layer);
    });
    return res;
  });

  childLayers: Signal<boolean[]> = computed(() =>
    this.boxes().map((box) =>
      this.currentLayer() != null && this.currentLayer().children != null
        ? this.currentLayer().children?.includes(box.layer)
        : false,
    ),
  );

  showMovableLayer: boolean = true;

  debounceMove;

  _guidelines = Array(9)
    .fill(1)
    .map((_1, i) => (i + 1) * (100 / 10));

  //All boxes and the corresponding image

  boxes: WritableSignal<layerBox[]> = signal([]);
  images: Signal<ImageBox[]> = computed(() =>
    this.boxes().map((box) => this.box2Image(box)),
  );

  currentLayer: WritableSignal<layerBox | null> = signal(null);
  currentImage: Signal<ImageBox | null> = computed(() => {
    if (this.currentLayer() == null) {
      return null;
    }
    const box = this.currentLayer();

    return this.box2Image(box);
  });

  //For balloon menu parent
  currentLayerElement: Signal<HTMLDivElement | null> = computed(() => {
    this.zoomFactor(); //Update when zoom factor changes
    if (this.currentLayer()?.children == null) {
      return null;
    }
    return this.boxGrid.nativeElement.getElementsByClassName("balloon")[0];
  });

  //Show children in groups and multi select
  showChildLayers: Signal<boolean[]> = computed(() => {
    if (this.currentLayer() == null || this.currentLayer().children == null) {
      return this.boxes().map((_) => false);
    }
    return this.boxes().map((box) =>
      this.currentLayer().children.includes(box.layer),
    );
  });

  //Snap variables and signals

  snapThreshold = 0.5;

  snap: Signal<Snap> = computed(() => {
    if (this.currentLayer() == null || !this.allowSnap()) {
      return {
        top: false,
        middle: false,
        bottom: false,
        right: false,
        center: false,
        left: false,
      };
    }

    const box = this.currentLayer().box;
    const canvas = {
      x: 0,
      y: 0,
      width: 100,
      height: 100,
    };

    return this.checkAlignment(box, canvas);
  });

  interLayerDists: Signal<(BoxDist | null)[] | null> = computed(() => {
    if (this.currentLayer() == null) {
      return null;
    }
    const currBox = this.currentLayer().box;
    return this.boxes().map((box, i) => {
      if (
        box.layer === this.currentLayer().layer ||
        this.currentLayer().children?.includes(box.layer) ||
        box.children?.includes(this.currentLayer().layer) ||
        this.checkChildren(this.currentLayer().children ?? [], box.layer) ||
        !this.showBoxes()[i]
      ) {
        return null;
      }

      const horizontal = ["top", "bottom", "middle"];
      const vertical = ["left", "right", "center"];

      const out: any = {};
      const bound = CBoxHelper.box2Bounding(box.box);
      const currBound = CBoxHelper.box2Bounding(currBox);

      horizontal.forEach((i) => {
        const dists: any = {};
        horizontal.forEach((j) => {
          dists[j] = CMath.dist(currBound[i], bound[j]);
        });
        out[i] = dists;
      });

      vertical.forEach((i) => {
        const dists: any = {};
        vertical.forEach((j) => {
          dists[j] = CMath.dist(currBound[i], bound[j]);
        });
        out[i] = dists;
      });

      return out;
    });
  });

  interLayerSnap: Signal<Snap> = computed(() => {
    const currLayer = this.currentLayer();
    const empty = {
      top: false,
      middle: false,
      bottom: false,
      right: false,
      center: false,
      left: false,
    };
    if (currLayer == null || !this.allowSnap()) {
      return empty;
    }
    const isAligned = this.boxes()
      .filter(
        (box, i) =>
          box.layer !== currLayer.layer &&
          !currLayer.children?.includes(box.layer) &&
          !this.checkChildren(currLayer.children ?? [], box.layer) &&
          !box.children?.includes(currLayer.layer) &&
          this.showBoxes()[i],
      )
      .map((box) => {
        return this.checkAlignment(currLayer.box, box.box, false);
      });
    if (isAligned.length === 0) {
      return empty;
    }

    const snap = isAligned.reduce((snap1: Snap, snap2: Snap) => {
      return {
        top: snap1.top || snap2.top,
        middle: snap1.middle || snap2.middle,
        bottom: snap1.bottom || snap2.bottom,
        right: snap1.right || snap2.right,
        center: snap1.center || snap2.center,
        left: snap1.left || snap2.left,
      };
    });
    return snap;
  });

  snapBoxes: WritableSignal<Set<string>> = signal(new Set());

  highlightBoxes: Signal<boolean[]> = computed(() =>
    this.boxes().map((box) => {
      return this.snapBoxes().has(box.layer);
    }),
  );

  _snaplines: Signal<
    {
      left: number;
      top: number;
      width: number;
      height: number;
      show: boolean;
      id: string;
    }[]
  > = computed(() => {
    const left =
      (this.canvas.width - this.canvas.innerResX * this.zoomFactor()) / 2;
    const top =
      (this.canvas.height - this.canvas.innerResY * this.zoomFactor()) / 2;
    const bottom = top + this.zoomFactor() * this.canvas.innerResY;
    const right = left + this.zoomFactor() * this.canvas.innerResX;
    const middle = this.canvas.height / 2;
    const center = this.canvas.width / 2;

    const topOffset =
      this.canvas.innerResY *
      this.zoomFactor() *
      (this.currentLayer()?.box.y / 100);
    const leftOffset =
      this.canvas.innerResX *
      this.zoomFactor() *
      (this.currentLayer()?.box.x / 100);
    const bottomOffset =
      this.canvas.innerResY *
      this.zoomFactor() *
      (1 -
        (this.currentLayer()?.box.y + this.currentLayer()?.box.height) / 100);
    const rightOffset =
      this.canvas.innerResX *
      this.zoomFactor() *
      (1 - (this.currentLayer()?.box.x + this.currentLayer()?.box.width) / 100);

    const middleOffset =
      top +
      this.canvas.innerResY *
        this.zoomFactor() *
        ((this.currentLayer()?.box.y + this.currentLayer()?.box.height / 2) /
          100);
    const centerOffset =
      left +
      this.canvas.innerResX *
        this.zoomFactor() *
        ((this.currentLayer()?.box.x + this.currentLayer()?.box.width / 2) /
          100);
    return [
      {
        id: "top",
        left: left,
        top: this.interLayerSnap().top ? top + topOffset : top,
        width: this.zoomFactor() * this.canvas.innerResX,
        height: 1,
        show: this.snap().top || this.interLayerSnap().top,
      },
      {
        id: "left",
        left: this.interLayerSnap().left ? left + leftOffset : left,
        top: top,
        width: 1,
        height: this.zoomFactor() * this.canvas.innerResY,
        show: this.snap().left || this.interLayerSnap().left,
      },
      {
        id: "bottom",
        left: left,
        top:
          (this.interLayerSnap().bottom ? bottom - bottomOffset : bottom) - 1,
        width: this.zoomFactor() * this.canvas.innerResX,
        height: 1,
        show: this.snap().bottom || this.interLayerSnap().bottom,
      },
      {
        id: "right",
        left: (this.interLayerSnap().right ? right - rightOffset : right) - 1,
        top: top,
        width: 1,
        height: this.zoomFactor() * this.canvas.innerResY,
        show: this.snap().right || this.interLayerSnap().right,
      },
      {
        id: "middle",
        left: left,
        top: this.interLayerSnap().middle ? middleOffset : middle,
        width: this.zoomFactor() * this.canvas.innerResX,
        height: 1,
        show:
          (this.snap().middle || this.interLayerSnap().middle) &&
          !this.isResizing(),
      },
      {
        id: "center",
        left: this.interLayerSnap().center ? centerOffset : center,
        top: top,
        width: 1,
        height: this.zoomFactor() * this.canvas.innerResY,
        show:
          (this.snap().center || this.interLayerSnap().center) &&
          !this.isResizing(),
      },
    ];
  });

  //Cache for moving and resizing layer
  dragOrigin: Box;

  resizeElement;
  resizeDirection: string;
  positionInElement: { x: number; y: number };
  pickUp: Point;

  cache = {
    element: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    box: {
      x: 0,
      y: 0,
      width: 100,
      height: 100,
    },
    currentPos: null,
    mousePos: null,
    dragPos: null,
    layerBoxes: null,
  };

  //Canvas size values
  canvas: {
    width: number;
    height: number;
    innerResX: number;
    innerResY: number;
  } = { height: 0, width: 0, innerResX: 0, innerResY: 0 };

  //Multi select box
  selectOrigin: WritableSignal<Point> = signal({ x: 0, y: 0 });
  selectPos: WritableSignal<Point> = signal({ x: 0, y: 0 });
  selectBox: Signal<Box> = computed(() => {
    const origin = this.selectOrigin();
    const pos = this.selectPos();

    const topLeft = this.pxToPercent({
      x: Math.min(origin.x, pos.x),
      y: Math.min(origin.y, pos.y),
    });
    const bottomRight = this.pxToPercent({
      x: Math.max(origin.x, pos.x),
      y: Math.max(origin.y, pos.y),
    });

    return {
      x: topLeft.x,
      y: topLeft.y,
      width: bottomRight.x - topLeft.x,
      height: bottomRight.y - topLeft.y,
    };
  });
  selectLayers: Signal<layerBox[]> = computed(() => {
    const boxes = this.boxes();
    const superBox = boxes.filter((box) => {
      return CBoxHelper.boxSubset(this.selectBox(), box.box);
    });

    const superIndex =
      superBox.length > 0
        ? boxes.findIndex((box) => box.layer === superBox[0].layer)
        : boxes.length;

    const intersectionBoxes = boxes.filter(
      (box, i) =>
        CBoxHelper.boxIntersection(this.selectBox(), box.box) && i < superIndex,
    );
    const groups = intersectionBoxes.filter((box) => box.children != null);
    const interNoGroups = intersectionBoxes.filter(
      (box) => !groups.some((group) => group.children.includes(box.layer)),
    );

    return interNoGroups;
  });
  selectHighlight: Signal<boolean[]> = computed(() =>
    this.boxes().map((box) =>
      this.selectLayers()
        .map((sb) => sb.layer)
        .includes(box.layer),
    ),
  );

  disableDragSelect: WritableSignal<boolean> = signal(false);

  private readonly destroy$$ = new Subject<void>();

  private readonly document = inject(DOCUMENT);

  constructor(
    private mediaService: MediaService,
    private popUpService: CPopupModalService,
  ) {}

  ngOnInit(): void {
    this._live.refreshedLayer$.subscribe((layerIdent) => {
      this.buildBoxes();
      this.showMovableLayer = true;
    });
    this._editor.layerChanged$.subscribe((_) => {
      this.buildBoxes();
      this._live.invalidateCurrentLayer();
      this._live.checkLayers();
      if (!this.allowStack) {
        this.layerStack = [];
      }
    });
    this._editor.layerMoved$.subscribe((_) => {
      this.buildBoxes();
    });
    this._editor.layerAddRemove$.subscribe((_) => {
      this.buildBoxes();
    });

    this._editor.layerHoverLayerListSubject.subscribe(
      (hoveredLayer: CreativeLayer) => {
        if (!hoveredLayer) {
          this.hoverBox.update(() => null);
          this.focusBox.update(() => null);
          this.balloon.setPosition();
          this.isEditing.update(() => false);
          return;
        }
        const hoveredBox = {
          ...(hoveredLayer.layers != null && {
            children: hoveredLayer.layers.map((l) => l.identifier),
          }),
          layer: hoveredLayer.identifier,
          box: hoveredLayer.position,
          img: this._live.getLayerSpec(hoveredLayer.identifier),
          config: hoveredLayer.config?.grid_config[0].config ?? {},
        };
        this.hoverBox.update(() => hoveredLayer.identifier);
        this.focusBox.update(() => hoveredBox);
      },
    );

    this._editor.zoomChanged$.subscribe({
      next: (_zoom) => {
        this.balloon.setPosition();
      },
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setCanvas();
    });
  }

  ngOnDestroy(): void {
    this.destroy$$.next();
  }

  //Helper function to build image postions from box positions
  box2Image(box) {
    const res = box.img?.response?.sizes.resolution ?? [2, 2];

    const theight = box.img?.response?.sizes.target_size.height ?? 2;
    const twidth = box.img?.response?.sizes.target_size.width ?? 2;

    const rheight = box.img?.response?.sizes.resized_size.height ?? 2;
    const rwidth = box.img?.response?.sizes.resized_size.width ?? 2;

    const fheight = box.img?.response?.sizes.final_size.height ?? 2;
    const fwidth = box.img?.response?.sizes.final_size.width ?? 2;

    const tbox = {
      height: box.box.height,
      width: box.box.width,
    };
    const rbox = {
      height: (tbox.height * rheight) / theight,
      width: (tbox.width * rwidth) / twidth,
    };
    const fbox = {
      height: (rbox.height * fheight) / rheight,
      width: (rbox.width * fwidth) / rwidth,
    };

    const diffH = rbox.height - tbox.height;
    const diffW = rbox.width - tbox.width;

    const alignment = box.config.media_position ?? box.config.text_position;

    const x =
      alignment?.pos != null && !alignment.pos.includes("CENTER")
        ? alignment.pos.includes("LEFT")
          ? 0
          : diffW
        : diffW / 2;
    const y =
      alignment?.pos != null && !alignment?.pos.includes("MIDDLE")
        ? alignment?.pos.includes("TOP")
          ? 0
          : diffH
        : diffH / 2;

    const offsetX = (rbox.width - fbox.width) / 2;
    const offsetY = (rbox.height - fbox.height) / 2;

    const image = {
      x: box.box.x - x + offsetX,
      y: box.box.y - y + offsetY,
      width: fbox.width ?? 0,
      height: fbox.height ?? 0,
    };

    return {
      layer: box.layer,
      image: image,
      css: box.img?.css ?? "",
      config: box.config,
    };
  }

  //Helper function to get canvas percentile position from pixel position
  pxToPercent(point: Point) {
    const w = (this.canvas.innerResX * this.zoomFactor()) / 100;
    const h = (this.canvas.innerResY * this.zoomFactor()) / 100;

    const grid = this.boxGrid.nativeElement.getBoundingClientRect();

    const percentPoint: Point = {
      x: (point.x - grid.x) / w,
      y: (point.y - grid.y) / h,
    };

    return percentPoint;
  }

  //Layer selection functions

  selectLayer(event: MouseEvent, layer: string): void {
    event.stopPropagation();
    if (this.currentLayer() == null || this.currentLayer().layer !== layer) {
      this.allowStack = false;
      this._editor.selectLayerClick(event, layer);
      this.disableDragSelect.update(() => true);
      return;
    }
    if (
      this.currentLayer().layer === "multiSelection" &&
      (event.ctrlKey || event.metaKey)
    ) {
      this.allowStack = false;
      const point = this.pxToPercent({ x: event.clientX, y: event.clientY });
      const layersUnderMouse = this.boxes().filter(
        (box) =>
          this.currentLayer().children.includes(box.layer) &&
          CBoxHelper.pointInBox(box.box, point),
      );
      if (layersUnderMouse.length > 0) {
        this._editor.selectLayerMulti(layersUnderMouse[0].layer);
      }
    }
  }

  deselect() {
    this.disableDragSelect.update(() => false);
    this._editor.deselectLayer();
  }

  selectBelow(event: MouseEvent) {
    if (this.currentLayer() == null) {
      return;
    }

    const point = {
      x:
        this.currentLayer().box.x +
        (event.offsetX / (this.canvas.innerResX * this.zoomFactor())) * 100,
      y:
        this.currentLayer().box.y +
        (event.offsetY / (this.canvas.innerResY * this.zoomFactor())) * 100,
    };

    const boxes = this.boxes().filter((box) =>
      CBoxHelper.pointInBox(box.box, point),
    );

    const currIndex = boxes.findIndex(
      (box) => box.layer === this.currentLayer().layer,
    );

    if (this.currentLayer().layer === "multiSelection") {
      this._editor.selectLayer(boxes[0].layer);
      return;
    }

    if (boxes[currIndex + 1]) {
      this.allowStack = true;
      this.layerStack.push(this.currentLayer().layer);
      this._editor.selectLayer(boxes[currIndex + 1].layer);
    }
  }

  //Main function to update boxes as things changes
  buildBoxes() {
    if (this.isEditing()) {
      return;
    }
    this.boxes.update(() =>
      Array.from(this._editor.iterateLayers(true)).map((layer) => {
        return {
          ...(layer.type === "group" && {
            children: layer.layers.map((l) => l.identifier),
          }),
          layer: layer.identifier,
          box:
            layer.type === "group"
              ? this.calcBoxes(layer.layers)
              : JSON.parse(JSON.stringify(layer.position)),
          img: this._live?.getLayerSpec(layer.identifier),
          config: layer.config?.grid_config[0].config ?? {},
        };
      }),
    );
    if (this._editor.layer != null) {
      this.currentLayer.update(() => {
        const layer = this._editor.layer;
        return {
          ...(layer.type === "group" && {
            children: layer.layers.map((l) => l.identifier),
          }),
          layer: layer.identifier,
          box:
            layer.type === "group"
              ? this.calcBoxes(layer.layers)
              : JSON.parse(JSON.stringify(layer.position)),
          img: this._live?.getLayerSpec(layer.identifier),
          config: layer.config?.grid_config[0].config ?? {},
        };
      });
    }
    if (this._editor.layer == null) {
      this.currentLayer.update(() => null);
    }
    if (this._editor.multiSelectedLayerIdentifiers.size > 0) {
      const multiBox = {
        layer: "multiSelection",
        box: this.calcBoxes(
          Array.from(
            iterateSpecificLayers(this._editor.multiSelectedLayers(), false),
          ),
        ),
        children: Array.from(this._editor.multiSelectedLayerIdentifiers),
        img: null,
        config: {},
      };
      this.boxes.update((boxes) => {
        boxes.push(multiBox);
        return boxes;
      });
      this.currentLayer.update(() => multiBox);
    }
  }

  //Helper function for calculating group boxes
  calcBoxes(layers: CreativeLayer[]) {
    const layersCopy: CreativeLayer[] = JSON.parse(JSON.stringify(layers));
    const boxes: Box[] = layersCopy.map((l) => l.position);

    return CBoxHelper.circumBox(boxes);
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.setCanvas();
  }

  //Makes sure canvas has correct size
  setCanvas() {
    const width = this.innerCanvas.nativeElement.offsetWidth;
    const height = this.innerCanvas.nativeElement.offsetHeight;
    const scaleByHeight =
      (height / this._resolution[1]) * this._resolution[0] < width;
    const innerResX = scaleByHeight
      ? (height / this._resolution[1]) * this._resolution[0]
      : width;
    const innerResY = scaleByHeight
      ? height
      : (width / this._resolution[0]) * this._resolution[1];
    this.canvas = {
      width: width,
      height: height,
      innerResX: innerResX * 0.7,
      innerResY: innerResY * 0.7,
    };
  }

  //Host listeners to check for key presses

  @HostListener("window:keydown", ["$event"])
  keyDownPreventScroll(event: KeyboardEvent) {
    if (
      this._editor.layer == null &&
      this._editor.multiSelectedLayerIdentifiers.size === 0
    ) {
      this.shift = false;
      this.alt = false;

      return;
    }

    this.shift = event.key === "Shift" || this.shift;
    this.alt = event.key === "Alt" || this.alt;

    if (this.isEditing() && (this.shift || this.alt)) {
      this.resizeMove(this.cache.currentPos);
    }

    const keyVectors = {
      [KEY_CODE.UP]: true,
      [KEY_CODE.DOWN]: true,
      [KEY_CODE.LEFT]: true,
      [KEY_CODE.RIGHT]: true,
    };

    const k =
      this.innerCanvas.nativeElement.matches(":hover") && keyVectors[event.key];

    if (k) {
      event.preventDefault();
    }
  }

  @HostListener("window:keyup", ["$event"])
  keyEvent(event: KeyboardEvent) {
    if (
      this._editor.layer == null &&
      this._editor.multiSelectedLayerIdentifiers.size === 0
    ) {
      this.shift = false;
      this.alt = false;

      return;
    }

    if (event.key === "Escape") {
      if (this.layerStack.length === 0) {
        this.deselect();
      } else {
        const lastLayer = this.layerStack.pop();
        this._editor.selectLayer(lastLayer);
      }
    }

    if (event.key === "Shift" || event.key === "Alt") {
      this.shift = false;
      this.alt = false;
      this.isEditing() && this.resizeMove(this.cache.currentPos);
    }

    const factor = event.shiftKey ? 2 : 0.5;

    const keyVectors = {
      [KEY_CODE.UP]: { x: 0, y: -1 * factor },
      [KEY_CODE.DOWN]: { x: 0, y: 1 * factor },
      [KEY_CODE.LEFT]: { x: -1 * factor, y: 0 },
      [KEY_CODE.RIGHT]: { x: 1 * factor, y: 0 },
    };

    const move =
      this.innerCanvas.nativeElement.matches(":hover") && keyVectors[event.key];

    if (move) {
      event.preventDefault();
      this.isEditing.update(() => true);
      this.applyMove(move);
      if (this.debounceMove != null) {
        clearTimeout(this.debounceMove);
        this.debounceMove = null;
      }
      this.debounceMove = setTimeout(() => {
        this.isEditing.update(() => false);

        this.commit();
        this.debounceMove = null;
      }, 500);
    }
  }

  //Move on arrow key press
  applyMove(point: Point) {
    this.cache.layerBoxes = JSON.parse(JSON.stringify(this.boxes()));
    const cl = this.currentLayer();
    const newBoxPos = {
      ...(cl.children != null && { children: cl.children }),
      box: {
        x: cl.box.x + point.x,
        y: cl.box.y + point.y,
        height: cl.box.height,
        width: cl.box.width,
      },
      layer: cl.layer,
      config: cl.config,
      img: cl.img,
    };
    if (cl.children != null) {
      this.moveInGroup(newBoxPos, cl.box);
    }
    this.currentLayer.update(() => newBoxPos);
    this.boxes.update(() =>
      this.boxes().map((box) => {
        if (box.layer !== cl.layer) {
          return box;
        }
        return newBoxPos;
      }),
    );
  }

  //Helper functions to check various conditions

  checkAlignment(box1: Box, box2: Box, simple = true) {
    const bound1 = CBoxHelper.box2Bounding(box1);
    const bound2 = CBoxHelper.box2Bounding(box2);

    const vertical = ["left", "right", "center"];
    const horizontal = ["top", "bottom", "middle"];

    const veritcalSnap = vertical.map((i) =>
      vertical.map(
        (j) => CMath.dist(bound1[i], bound2[j]) < this.snapThreshold,
      ),
    );

    const horizontalSnap = horizontal.map((i) =>
      horizontal.map(
        (j) => CMath.dist(bound1[i], bound2[j]) < this.snapThreshold,
      ),
    );

    const calcBool = (arr: any, index: number) => {
      return (
        arr[index][index] ||
        ((arr[index][CMath.mod(index + 1, 3)] ||
          arr[index][CMath.mod(index - 1, 3)]) &&
          !simple)
      );
    };

    return {
      top: calcBool(horizontalSnap, 0),
      middle: calcBool(horizontalSnap, 2),
      bottom: calcBool(horizontalSnap, 1),
      right: calcBool(veritcalSnap, 1),
      center: calcBool(veritcalSnap, 2),
      left: calcBool(veritcalSnap, 0),
    };
  }

  checkChildren(children: string[], layer: string) {
    const boxes = this.boxes().filter((box) => children.includes(box.layer));
    return boxes.some(
      (box) => box.children != null && box.children.includes(layer),
    );
  }

  //Drag to select functions

  startSelect = (pos: Point) => {
    this.selectOrigin.update(() => pos);
    this.selectPos.update(() => pos);
    this.isSelecting.update(() => true);
    setTimeout(() => {
      this._editor.deselectLayer();
    });
  };

  moveSelect = (dist: Point) => {
    this.selectPos.update(() => {
      const origin = this.selectOrigin();
      return { x: origin.x + dist.x, y: origin.y + dist.y };
    });
  };

  endSelect = () => {
    const zero = { x: 0, y: 0 };

    this.selectLayers().forEach((layer) =>
      this._editor.selectLayerMulti(layer.layer),
    );

    setTimeout(() => {
      this._editor.layerChangedSource.next(true);
      this.isSelecting.update(() => false);

      this.selectOrigin.update(() => zero);
      this.selectPos.update(() => zero);
    });
  };

  //Helper functions for moving layers

  moveInGroup(layerGroup: layerBox, box: Box, recurred: boolean = false) {
    if (layerGroup.children == null) {
      return;
    }
    const deltaX = layerGroup.box.x - box.x;
    const deltaY = layerGroup.box.y - box.y;
    const deltaW = layerGroup.box.width - box.width;
    const deltaH = layerGroup.box.height - box.height;

    this.boxes.update((boxes) => {
      const updateBoxes = boxes.map((b) => {
        if (layerGroup.children.includes(b.layer)) {
          const origin = this.cache.layerBoxes.find(
            (elm) => elm.layer === b.layer,
          ).box;
          b.box.x = origin.x + deltaX;
          b.box.y = origin.y + deltaY;
          b.box.width = origin.width + deltaW;
          b.box.height = origin.height + deltaH;
          if (b.children && !recurred) {
            this.moveInGroup(b, origin, true);
          }
        }
        return b;
      });
      return updateBoxes;
    });
  }

  //Helper functions for resizing layers

  _resize_calc(event: MouseEvent): { x: number; y: number } {
    return {
      x: event.pageX - this.canvas.innerResX * 2 - this.cache.mousePos.x,
      y: event.pageY - this.canvas.innerResY * 2 - this.cache.mousePos.y,
    };
  }

  _move(event: MouseEvent, dir: string, mirror: boolean = false) {
    const w = this.grid.nativeElement.offsetWidth;
    const h = this.grid.nativeElement.offsetHeight;

    const calc = this._resize_calc(event);

    const box = { x: 0, y: 0, width: 100, height: 100 };

    const horizontal = dir.includes("r") || dir.includes("l");
    const vertical = dir.includes("t") || dir.includes("b");

    const width = this.cache.box.width;
    const height = this.cache.box.height;
    const left = this.cache.box.x;
    const top = this.cache.box.y;

    const calcX = (calc.x / w) * 100;
    const calcY = (calc.y / h) * 100;

    box.x = Math.min(
      horizontal && dir.includes("l")
        ? left + calcX
        : horizontal && mirror
          ? left - calcX
          : left,
      left + width / (mirror ? 2 : 1),
    );
    box.y = Math.min(
      vertical && dir.includes("t")
        ? top + calcY
        : vertical && mirror
          ? top - calcY
          : top,
      top + height / (mirror ? 2 : 1),
    );
    box.width = Math.max(
      horizontal
        ? width + (mirror ? 2 : 1) * (dir.includes("l") ? -1 : 1) * calcX
        : width,
      0,
    );
    box.height = Math.max(
      vertical
        ? height + (mirror ? 2 : 1) * (dir.includes("t") ? -1 : 1) * calcY
        : height,
      0,
    );
    this.currentLayer.update((layer) => {
      return {
        ...(layer.children && { children: layer.children }),
        box: this.snapResize(box, dir.includes("r"), dir.includes("b")),
        layer: layer.layer,
        img:
          layer.layer === "multiSelection"
            ? null
            : this._live.getLayerSpec(layer.layer),
        config: layer.config,
      };
    });
    this.boxes.update((boxes) => {
      boxes.map((box) => {
        if (box.layer === this.currentLayer().layer) {
          box.box = this.currentLayer().box;
        }
        return box;
      });
      return boxes;
    });
  }

  _constrainAspect(event: MouseEvent, dir: string) {
    //Get the width and height of the canvas element so we can covert to percentage
    const h = this.grid.nativeElement.offsetHeight;
    const w = this.grid.nativeElement.offsetWidth;

    //Get both movements in x and y coordinates and convert to percentage
    const calc: any = {
      horizontal:
        (dir.includes("l") ? 1 : -1) * (this._resize_calc(event).x / w) * 100,
      vertical:
        (dir.includes("t") ? 1 : -1) * (this._resize_calc(event).y / h) * 100,
    };

    const width = this.cache.box.width;
    const height = this.cache.box.height;
    const left = this.cache.box.x;
    const top = this.cache.box.y;

    const box = { x: 0, y: 0, width: 100, height: 100 };

    //Check which direction has the largest change, so we can constrain the
    //asepct ratio accordingly.
    const snapDir = Object.keys(calc).reduce((a, b) =>
      calc[a] > calc[b] ? a : b,
    );
    const ratio = this.cache.box.height / this.cache.box.width;

    //Depending on which corner is being pulled, which values are changed is different.
    box.y = Math.min(
      dir.includes("t")
        ? this.cache.box.y +
            calc[snapDir] * (snapDir === "horizontal" ? ratio : 1)
        : this.cache.box.y,
      top + height - 5,
    );
    box.x = Math.min(
      dir.includes("l")
        ? this.cache.box.x +
            calc[snapDir] / (snapDir === "vertical" ? ratio : 1)
        : this.cache.box.x,
      left + width - 5,
    );

    box.height = Math.max(
      this.cache.box.height -
        calc[snapDir] * (snapDir === "horizontal" ? ratio : 1),
      5,
    );
    box.width = Math.max(
      this.cache.box.width -
        calc[snapDir] / (snapDir === "vertical" ? ratio : 1),
      5,
    );

    this.currentLayer.update((layer) => {
      return {
        ...(layer.children && { children: layer.children }),
        box: box,
        layer: layer.layer,
        img:
          layer.layer === "multiSelection"
            ? null
            : this._live.getLayerSpec(layer.layer),
        config: layer.config,
      };
    });
    this.boxes.update((boxes) => {
      boxes.map((box) => {
        if (box.layer === this.currentLayer().layer) {
          box.box = this.currentLayer().box;
        }
        return box;
      });
      return boxes;
    });
  }

  resizeInGroup(layerGroup: layerBox, box: Box, recurred = false) {
    if (layerGroup.children == null) {
      return;
    }

    const ratioW = layerGroup.box.width / box.width;
    const ratioH = layerGroup.box.height / box.height;

    this.boxes.update((boxes) => {
      const updateBoxes = boxes.map((b) => {
        if (layerGroup.children.includes(b.layer)) {
          const origin = this.cache.layerBoxes.find(
            (elm) => elm.layer === b.layer,
          ).box;
          b.box.x = layerGroup.box.x + (origin.x - box.x) * ratioW;
          b.box.y = layerGroup.box.y + (origin.y - box.y) * ratioH;
          b.box.width = origin.width * ratioW;
          b.box.height = origin.height * ratioH;
          if (b.children && !recurred) {
            this.resizeInGroup(b, origin, true);
          }
        }
        return b;
      });
      return updateBoxes;
    });
  }

  layerImage(layer: string): string {
    return this._live.getLayerCSS(layer);
  }

  //Helper functions for snapping

  _getSide(side: string, box: Box, layerIdx: number, horizontal: boolean) {
    const dist = this.interLayerDists()[layerIdx];
    const index = CMath.argMin(
      dist != null
        ? [
            dist[side][horizontal ? "left" : "top"],
            dist[side][horizontal ? "center" : "middle"],
            dist[side][horizontal ? "right" : "bottom"],
          ]
        : [100, 100, 100],
    );
    const topLeft = box[horizontal ? "x" : "y"];
    const widthHeight = box[horizontal ? "width" : "height"];
    switch (index) {
      case 0:
        return topLeft;
      case 1:
        return topLeft + widthHeight / 2;
      case 2:
        return topLeft + widthHeight;
    }
  }

  snapToLayer(side: string, percent = false) {
    const horizontal = new Set<string>(["left", "right", "center"]).has(side);

    const layerIndex = Math.floor(
      CMath.argMin(
        this.interLayerDists()
          .map((dist) =>
            dist != null
              ? [
                  dist[side][horizontal ? "left" : "top"],
                  dist[side][horizontal ? "center" : "middle"],
                  dist[side][horizontal ? "right" : "bottom"],
                ]
              : [100, 100, 100],
          )
          .reduce((accumulator, value) => accumulator.concat(value), []),
      ) / 3,
    );
    const snapBox = this.boxes()[layerIndex];
    const layerDistPercent = this._getSide(
      side,
      snapBox.box,
      layerIndex,
      horizontal,
    );
    const layerDist =
      (layerDistPercent / 100) *
      (horizontal
        ? this.canvas.innerResX * this.zoomFactor()
        : this.canvas.innerResY * this.zoomFactor());

    const out = percent ? layerDistPercent : layerDist;
    return { dist: out, box: snapBox };
  }

  snapMove = (point: Point, dragRef: DragRef, _, pickup: Point) => {
    this.pickUp = pickup;

    // Size of grid
    const w = this.grid.nativeElement.offsetWidth;
    const h = this.grid.nativeElement.offsetHeight;

    // Dragging element bounds
    const rootElement = dragRef.getRootElement();
    const bounds = rootElement.getBoundingClientRect();

    // Center of grid on screen
    const gridBounds = this.grid.nativeElement.getBoundingClientRect();
    const grid = {
      left: gridBounds.left,
      right: w + gridBounds.left,
      center: w / 2 + gridBounds.left,
      top: gridBounds.top,
      bottom: h + gridBounds.top,
      middle: h / 2 + gridBounds.top,
    };

    // Position of mouse in box
    const mouseInBox = pickup;

    // The difference from the mouse to center of the box
    const mouseDiff = {
      left: mouseInBox.x,
      right: mouseInBox.x - bounds.width,
      center: mouseInBox.x - bounds.width / 2,
      top: mouseInBox.y,
      bottom: mouseInBox.y - bounds.height,
      middle: mouseInBox.y - bounds.height / 2,
    };

    // Distance from center of box to center of grid
    const centerXDistance = Math.abs(point.x - mouseDiff.center - grid.center);
    const centerYDistance = Math.abs(point.y - mouseDiff.middle - grid.middle);
    const leftDistance = Math.abs(point.x - mouseDiff.left - grid.left);
    const topDistance = Math.abs(point.y - mouseDiff.top - grid.top);
    const rightDistance = Math.abs(point.x - mouseDiff.right - grid.right);
    const bottomDistance = Math.abs(point.y - mouseDiff.bottom - grid.bottom);

    //Snap to other layers

    const snapBoxes = new Set([]);

    const snap: any = {};
    ["left", "right", "center", "top", "bottom", "middle"].forEach((side) => {
      const horizontal = ["left", "right", "center"].includes(side);
      const layerCalc = this.snapToLayer(side);
      const layerDist = layerCalc.dist;
      const layerBox = layerCalc.box.layer;

      const snapLayer =
        Math.abs(
          point[horizontal ? "x" : "y"] -
            mouseDiff[side] -
            grid[horizontal ? "left" : "top"] -
            layerDist,
        ) <
          ((horizontal ? this.canvas.innerResX : this.canvas.innerResY) *
            this.zoomFactor() *
            this.snapThreshold) /
            100 && this.interLayerSnap()[side];
      if (snapLayer) {
        snapBoxes.add(layerBox);
      }

      snap[side] = { dist: layerDist, snap: snapLayer };
    });
    const threshX =
      (this.canvas.innerResX * this.zoomFactor() * this.snapThreshold) / 100;
    const threshY =
      (this.canvas.innerResY * this.zoomFactor() * this.snapThreshold) / 100;
    // Snap if distance < threshold
    const snapCenterX = centerXDistance < threshX && this.allowSnap();
    const snapCenterY = centerYDistance < threshY && this.allowSnap();
    const snapLeft = leftDistance < threshX && this.allowSnap();
    const snapRight = rightDistance < threshX && this.allowSnap();
    const snapTop = topDistance < threshY && this.allowSnap();
    const snapBottom = bottomDistance < threshY && this.allowSnap();

    // Select new coordinates if snapping
    const newX = snapCenterX
      ? grid.center + mouseDiff.center
      : snapLeft
        ? grid.left + mouseDiff.left
        : snapRight
          ? grid.right + mouseDiff.right
          : snap.left.snap
            ? grid.left + snap.left.dist + mouseDiff.left
            : snap.right.snap
              ? grid.left + snap.right.dist + mouseDiff.right
              : snap.center.snap
                ? grid.left + snap.center.dist + mouseDiff.center
                : point.x;

    const newY = snapCenterY
      ? grid.middle + mouseDiff.middle
      : snapTop
        ? grid.top + mouseDiff.top
        : snapBottom
          ? grid.bottom + mouseDiff.bottom
          : snap.top.snap
            ? grid.top + snap.top.dist + mouseDiff.top
            : snap.bottom.snap
              ? grid.top + snap.bottom.dist + mouseDiff.bottom
              : snap.middle.snap
                ? grid.top + snap.middle.dist + mouseDiff.middle
                : point.y;

    this.snapBoxes.update(() => snapBoxes);

    return {
      x: newX - pickup.x,
      y: newY - pickup.y,
    };
  };

  snapResize(box: Box, right: boolean, bottom: boolean) {
    const boxCopy = JSON.parse(JSON.stringify(box));

    const snapBoxes = new Set([]);

    const snapX = this.snapToLayer(right ? "right" : "left", true);
    const snapY = this.snapToLayer(bottom ? "bottom" : "top", true);

    const sideX = snapX.dist;
    const sideY = snapY.dist;

    const bound = CBoxHelper.box2Bounding(box);

    if (
      CMath.dist(sideX, bound.left) < this.snapThreshold * 1.5 &&
      this.interLayerSnap().left &&
      !right
    ) {
      boxCopy.x = sideX;
      boxCopy.width = boxCopy.width + (box.x - sideX);
      snapBoxes.add(snapX.box.layer);
    }
    if (
      CMath.dist(sideX, bound.right) < this.snapThreshold * 1.5 &&
      this.interLayerSnap().right &&
      right
    ) {
      boxCopy.width = sideX - boxCopy.x;
      snapBoxes.add(snapX.box.layer);
    }

    if (
      CMath.dist(sideY, bound.top) < this.snapThreshold * 1.5 &&
      this.interLayerSnap().top &&
      !bottom
    ) {
      boxCopy.y = sideY;
      boxCopy.height = boxCopy.height + (box.y - sideY);
      snapBoxes.add(snapY.box.layer);
    }
    if (
      CMath.dist(sideY, bound.bottom) < this.snapThreshold * 1.5 &&
      this.interLayerSnap().bottom &&
      bottom
    ) {
      boxCopy.height = sideY - boxCopy.y;
      snapBoxes.add(snapY.box.layer);
    }

    if (Math.abs(box.x) < this.snapThreshold * 1.5) {
      boxCopy.x = 0;
      boxCopy.width = boxCopy.width + box.x;
      snapBoxes.add(snapX.box.layer);
    }
    if (Math.abs(box.y) < this.snapThreshold * 1.5) {
      boxCopy.y = 0;
      boxCopy.height = boxCopy.height + box.y;
      snapBoxes.add(snapY.box.layer);
    }
    if (Math.abs(box.x + box.width - 100) < this.snapThreshold * 1.5) {
      boxCopy.width = 100 - boxCopy.x;
      snapBoxes.add(snapX.box.layer);
    }
    if (Math.abs(box.y + box.height - 100) < this.snapThreshold * 1.5) {
      boxCopy.height = 100 - boxCopy.y;
      snapBoxes.add(snapY.box.layer);
    }

    this.snapBoxes.update(() => snapBoxes);

    return boxCopy;
  }

  //Moving layer

  dragStart(event: any) {
    //CdkDragEvent or something
    this.cache.dragPos = { x: event.event.offsetX, y: event.event.offsetY };
    this.cache.box = JSON.parse(JSON.stringify(this.currentLayer().box));
    this.cache.layerBoxes = JSON.parse(JSON.stringify(this.boxes()));

    this.isEditing.update(() => true);
  }

  dragMove(event: any) {
    //Some cdkdrag event
    const dist = CMath.add(event.distance, this.pickUp);

    // Calculate current moving distance offset
    this.currentLayer.update((cl) => {
      return {
        ...(cl.children != null && { children: cl.children }),
        layer: cl.layer,
        box: {
          x:
            (dist.x / (this.canvas.innerResX * this.zoomFactor())) * 100 +
            this.cache.box.x,
          y:
            (dist.y / (this.canvas.innerResY * this.zoomFactor())) * 100 +
            this.cache.box.y,
          height: this.cache.box.height,
          width: this.cache.box.width,
        },
        img:
          cl.layer === "multiSelection"
            ? null
            : this._live.getLayerSpec(cl.layer),
        config: cl.config,
      };
    });
    if (this.currentLayer().children != null) {
      this.moveInGroup(this.currentLayer(), this.cache.box);
    }
  }

  dragEnd(event: any) {
    //Some cdk drag event
    this.isEditing.update(() => false);
    this.snapBoxes.update(() => new Set([]));

    this.commit();

    // Commit change to data source here and reset drag transform
    event.source.reset();

    this._live.checkLayers();
  }

  //Show layer on hover

  mouseEnter(layer: string) {
    if (this.isEditing()) {
      return;
    }
    if (this.currentLayer()?.layer === layer) {
      this.disableDragSelect.update(() => true);
    }
    this.hoverBox.update(() => layer);
    this._editor.canvasHoverLayerListSubject.next(this._editor.getLayer(layer));
  }
  mouseLeave(layer) {
    if (this.currentLayer()?.layer === layer) {
      this.disableDragSelect.update(() => false);
    }
    this.hoverBox.update(() => null);
    this._editor.canvasHoverLayerListSubject.next(null);
  }

  //Resize layer

  beginResize(dir: string, event: MouseEvent) {
    this.isHovering.update(() => false);
    this._live.autoRefresh = false;
    this.isEditing.update(() => true);
    this.isResizing.update(() => true);

    event.preventDefault();

    this.resizeElement = event.target as any;
    this.resizeDirection = dir;
    this.cache.mousePos = {
      x: event.pageX - this.canvas.innerResX * 2,
      y: event.pageY - this.canvas.innerResY * 2,
    };

    this.cache.element = this.resizeElement.getBoundingClientRect();
    this.cache.box = { ...this.currentLayer().box };
    this.cache.layerBoxes = JSON.parse(JSON.stringify(this.boxes()));

    this.showMovableLayer = false;

    window.addEventListener("mousemove", this.resizeMove, false);
    window.addEventListener("mouseup", this.endResize, false);
  }

  resizeMove = (event: MouseEvent) => {
    this.cache.currentPos = event;

    if (!this.resizeDirection) {
      return;
    }
    if (!this.resizeDirection) {
      return;
    }
    if (!this.resizeElement) {
      return;
    }

    const funcs: any = {
      l: (evnt: MouseEvent) => {
        this._move(evnt, "l", this.alt);
      },
      t: (evnt: MouseEvent) => {
        this._move(evnt, "t", this.alt);
      },
      r: (evnt: MouseEvent) => {
        this._move(evnt, "r", this.alt);
      },
      b: (evnt: MouseEvent) => {
        this._move(evnt, "b", this.alt);
      },

      tl: (evnt: MouseEvent) => {
        if (this.shift) {
          this._constrainAspect(evnt, "tl");
          return;
        }
        this._move(evnt, "tl", this.alt);
      },
      tr: (evnt: MouseEvent) => {
        if (this.shift) {
          this._constrainAspect(evnt, "tr");
          return;
        }
        this._move(evnt, "tr", this.alt);
      },
      bl: (evnt: MouseEvent) => {
        if (this.shift) {
          this._constrainAspect(evnt, "bl");
          return;
        }
        this._move(evnt, "bl", this.alt);
      },
      br: (evnt: MouseEvent) => {
        if (this.shift) {
          this._constrainAspect(evnt, "br");
          return;
        }
        this._move(evnt, "br", this.alt);
      },
    };
    funcs[this.resizeDirection](event);
  };

  endResize = (event: MouseEvent) => {
    if (this.currentLayer().children) {
      this.resizeInGroup(this.currentLayer(), this.cache.box);
    }

    this.snapBoxes.update(() => new Set([]));
    this.isEditing.update(() => false);
    this.isHovering.update(() => true);
    this.isResizing.update(() => false);

    this.commit();

    window.removeEventListener("mousemove", this.resizeMove, false);
    window.removeEventListener("mouseup", this.endResize, false);
    this._editor.gridChangedSource.next(true);

    // Allow refresh again and check layers
    this._live.autoRefresh = true;
    this._live.invalidateCurrentLayer(); // Always refresh this layer
    this._live.checkLayers();
  };

  //When file is dropped into the editor
  onFileDropped(files: File[]) {
    const file = files[0];
    // this.isLoading = true;
    this.mediaService.uploadMediaFile(file).subscribe(
      (response: UploadMediaResponseInterface) => {
        this._editor.addLayer(CreativeCellType.MEDIA);
        this._editor.layer.config.grid_config[0].config = {
          media_url: {
            id: response.id,
            name: file["name"],
            source_key: response.source,
          },
        };
      },
      (error) => {
        this.popUpService.error({ title: "Upload failed" });
      },
      () => {
        // this.isLoading = false;
      },
    );
  }

  //Commit layer changes
  commit() {
    this._editor.history.beginSpecSnapshot();
    this.boxes.update((boxes) =>
      boxes.map((box) => {
        if (box.layer === this.currentLayer().layer) {
          box.box = this.currentLayer().box;
        }
        return box;
      }),
    );

    const layers = this._editor.layer
      ? [this._editor.layer]
      : this._editor.multiSelectedLayers();

    // All inner layers / no groups
    const _layers = Array.from(iterateSpecificLayers(layers, true));

    _layers.forEach((layer) => {
      const newPos = this.boxes().find(
        (elm) => elm.layer === layer.identifier,
      ).box;
      if (newPos) {
        layer.position = newPos;
      }
    });

    this._editor.layerMovedSource.next({ layer: layers[0] });

    this._editor.history.finishSpecSnapshot();
    this.balloon.setPosition();
  }
}
