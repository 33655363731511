import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";

import { ElementOverviewSection } from "@core/api-models/shared-elements.models";
import { SharedElementsService } from "@core/api/shared-elements.service";
import { SharedElementInterface } from "@core/models/shared-elements.types";
import { CreativesEditService } from "@core/services/creatives/creatives-edit.service";

import { CPopupModalService } from "@theme/@confect/services/confect-popup-modal.service";

import { Subscription } from "rxjs";

@Component({
  selector: "ngx-creatives-elements-sidebar",
  templateUrl: "./creatives-elements-sidebar.component.html",
  styleUrls: ["./creatives-elements-sidebar.component.scss"],
  standalone: false,
})
export class CreativesElementsSidebarComponent implements OnInit, OnDestroy {
  @Input() editor: CreativesEditService = null;
  @Input() filter = new Set<string>([]);

  @Output() elementPicked = new EventEmitter<any>();

  elements: ElementOverviewSection[];

  elementSubscription?: Subscription;

  @ViewChild("sharedElements") sharedElements: TemplateRef<any>;
  @ViewChild("privateElements") privateElements: TemplateRef<any>;

  constructor(
    private popUpService: CPopupModalService,
    private elementService: SharedElementsService,
  ) {}

  ngOnInit(): void {
    this.getElements();
    this.elementSubscription = this.elementService.newElementCreated$.subscribe(
      { next: (_) => this.getElements() },
    );
  }
  ngOnDestroy(): void {
    this.elementSubscription?.unsubscribe();
  }

  getElements() {
    this.elementService.getElementOverview().subscribe({
      next: (res) => {
        if (this.filter.size === 0) {
          this.elements = res;
          return;
        }
        this.elements = res.filter((element) => {
          return this.filter.has(element.category.key);
        });
      },
    });
  }

  selectElement(element: SharedElementInterface) {
    let elemToAdd = JSON.parse(JSON.stringify(element.spec));

    this.editor._migration__sliceToBoundBox(elemToAdd.layers);

    if (elemToAdd.layers.length === 1) {
      elemToAdd = elemToAdd.layers[0];
    }

    this.editor.addLayerSpec(elemToAdd);
    this.elementService.markElementUsed(element.id).subscribe({
      next: (_) => this.getElements(),
    });

    this.elementPicked.emit();
  }

  openElements(
    type: "public" | "private",
    category: { display: string; key: string },
  ) {
    this.popUpService.template({
      template: type === "private" ? this.privateElements : this.sharedElements,
      showClose: false,
      extra: { category: category },
    });
  }
}
