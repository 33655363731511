import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  TemplateRef,
} from "@angular/core";

import { DesignsService } from "@core/api/designs.service";

import { CModalTemplateComponent } from "@theme/@confect/components/modal-template/modal-template.component";
import { CModalService } from "@theme/@confect/services/confect-modal.service";

@Component({
  selector: "ngx-gradient-spec",
  templateUrl: "./gradient-spec.component.html",
  styleUrls: ["./gradient-spec.component.scss"],
  standalone: false,
})
export class GradientSpecComponent implements OnInit {
  @Input() specItem: any = null;

  @ViewChild("gradientSelect", { static: true })
  gradientSelect: TemplateRef<any>;

  // Just tracks the number of colors
  // If we use the _value directly
  // color picker will close on any change
  _colorTracker = [];

  // Parsed input values for the color picker
  _colorPickerValues = [];

  _value: any = null;

  get value(): any {
    return this._value;
  }
  @Input()
  set value(to: any) {
    this._value = JSON.parse(JSON.stringify(to));
    this.refreshColorPickerValues();
    this._colorTracker = new Array(to.colors.length).fill(0);
  }

  @Input() presetColors: any = [];

  @Input() parentSelection: any = null;

  @Output() valueChange = new EventEmitter<any>();

  @ViewChild("gradientWindow") gradientWindow: TemplateRef<any>;

  constructor(
    private designsService: DesignsService,
    private modalService: CModalService,
  ) {}

  ngOnInit() {}

  refreshColorPickerValues() {
    this._colorPickerValues = this._value.colors.map((c) =>
      this.designsService.outputColor(c),
    );
  }

  colorPickerChange(value, index) {
    this._value.colors[index] = this.designsService.parseColor(value);
    this.refreshColorPickerValues();
    this.valueChange.emit(this._value);
  }

  stepChange(value, index) {
    this._value.steps[index] = value;
    this.valueChange.emit(this._value);
  }

  addColor() {
    this._value.steps.push(100);
    this._value.colors.push([0, 0, 0]);
    this.refreshColorPickerValues();
    this._colorTracker.push(0);
    this.valueChange.emit(this._value);
  }

  removeColor(index) {
    this._value.steps.splice(index, 1);
    this._value.colors.splice(index, 1);
    this._colorTracker.splice(index, 1);
    this.refreshColorPickerValues();
    this.valueChange.emit(this._value);
  }

  browseGradients() {
    this.modalService.open({
      component: CModalTemplateComponent,
      inputs: { template: this.gradientWindow },
    });
  }

  gradientPresetSelected(gradient) {
    this._value = gradient;
    this.refreshColorPickerValues();
    this._colorTracker = new Array(gradient.colors.length).fill(0);
    this.valueChange.emit(this._value);
  }
}
