import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "ngx-position-spec",
  templateUrl: "./position-spec.component.html",
  styleUrls: ["./position-spec.component.scss"],
  standalone: false,
})
export class PositionSpecComponent implements OnInit {
  @Input() specItem: any = null;
  @Input() value: any = null;

  @Output() valueChange = new EventEmitter<any>();
  @Output() xValueChange = new EventEmitter<any>();
  @Output() yValueChange = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {}

  alignmentChange(direction, newValue) {
    const replaceIndex = direction === "h" ? 0 : 1;

    const split = this.value.pos.split("_");
    split[replaceIndex] = newValue;
    this.valueChange.emit(split.join("_"));
  }
}
