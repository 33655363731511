<div
  class="transition-all duration-300 fixed z-[999] w-full h-full top-0 left-0"
  [ngClass]="{
    'bg-slate-300/0 backdrop-blur-0': !isShown,
    'bg-slate-300/80 backdrop-blur-sm': isShown,
  }"
>
  <div
    class="w-full h-full transition-opacity duration-300"
    [ngClass]="{ 'opacity-0': !isShown, 'opacity-100': isShown }"
  >
    @if (raisedError != null) {
      <div
        class="rounded-md bg-red-50 p-4 error-message shadow-sm"
        style="z-index: 21000"
        [ngClass]="{
          'video-mode': editorMode === CreativeEditorMode.VIDEO,
          'image-mode': editorMode !== CreativeEditorMode.VIDEO,
        }"
      >
        <div class="flex items-center">
          <confect-icon
            icon="error_outlined"
            iconClass="h-5 w-5 text-red-400 text-2xl"
          />
          <div class="ml-3">
            <p class="text-sm font-medium text-red-800">
              Error: {{ raisedError.message }}
            </p>
          </div>
          <div class="ml-auto pl-3">
            <div class="-mx-1.5 -my-1.5">
              @if (raisedError.error_key === "NOT_ENOUGH_PRODUCTS") {
                <confect-button
                  (click)="editorRef.properties.resetProductFilter()"
                  size="xsmall"
                  type="white"
                  >Reset filter</confect-button
                >
              }
            </div>
          </div>
        </div>
      </div>
    }
    @if (editor?.sequences?.length > 0) {
      <div class="toolbar shadow">
        <div class="left-toolbar inline-flex items-center space-x-8">
          <div>
            <confect-icon-button
              icon="clear"
              iconClass="text-3xl"
              (click)="closeWarning()"
            />
          </div>
          <div class="inline-flex items-center space-x-2">
            <confect-icon-button
              icon="undo"
              iconClass="text-2xl"
              [disabled]="!editor.history.canUndo"
              (click)="editor.history.undo()"
              tooltip
              tooltipText="Undo"
              tooltipPosition="below"
            />
            <confect-icon-button
              icon="redo"
              iconClass="text-2xl"
              [disabled]="!editor.history.canRedo"
              (click)="editor.history.redo()"
              tooltip
              tooltipText="Redo"
              tooltipPosition="below"
            />
          </div>
        </div>
        <div class="center-toolbar">
          @if (!demoMode) {
            <confect-input
              [(ngModel)]="editor.name"
              placeholder="Design name"
              [centered]="true"
            />
          }
        </div>
        <div class="right-toolbar flex items-center justify-end space-x-3">
          <confect-button
            dialogBoxTarget
            dialogPosition="below"
            targetID="preview"
            type="secondary"
            size="small"
            (click)="editorRef.preview()"
            >Preview</confect-button
          >

          @if (!demoMode) {
            <div>
              <confect-button size="small" (click)="saveWarning()"
                >Save</confect-button
              >
            </div>
          }
          @if (demoMode) {
            <div>
              <confect-button
                size="small"
                (click)="freeTrial()"
                class="glow relative py-2"
                [class.glow]="demoMode && demoModePreviewClicked"
                >Start free trial</confect-button
              >
            </div>
          }
        </div>
      </div>
    }

    <confect-creatives-editor
      #editorComponent
      [editor]="editor"
      [live]="live"
      [(editorMode)]="editorMode"
      [videoSupport]="videoSupport"
      [allowModeSwitching]="true"
      [allowResolutionChange]="true"
      [forceSingleProduct]="true"
      [showPreview]="true"
      [extraSettings]="extraSettings"
      [helpEnabled]="helpEnabled"
      [(selectedPreviewFilters)]="selectedPreviewFilters"
      [previewFilterOptions]="previewFilterOptions"
      [(selectedResolution)]="selectedResolution"
      [specs]="specs"
    />
  </div>
</div>
