import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "ngx-bool-spec",
  templateUrl: "./bool-spec.component.html",
  styleUrls: ["./bool-spec.component.scss"],
  standalone: false,
})
export class BoolSpecComponent implements OnInit {
  @Input() specItem: any = null;
  @Input() value: any = null;
  @Output() valueChange = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {}
}
