import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Signal,
  ViewChild,
  WritableSignal,
  computed,
  signal,
  OnDestroy,
} from "@angular/core";

import { CreativeLayer } from "@core/models/creative.types";
import { IntroOutroEffectPreset } from "@core/services/creatives/preset-effects";

import { Observable, Subject, Subscription, takeUntil } from "rxjs";

export interface TimelineBlock {
  width: number;
  height: number;
  top: number;
  left: number;
  track: string;
  trackName: string;
  id: string;
  name: string;
  type: string;
  icon: string;
  effect?: {
    effect?: any;
    effect_in?: { settings: IntroOutroEffectPreset };
    effect_out?: { settings: IntroOutroEffectPreset };
  };
}

export interface TimelineBlockSpec {
  start: number;
  duration: number;
  track: string;
  trackName: string;
  id: string;
  name: string;
  type: string;
  effect?: {
    effect?: any;
    effect_in?: { settings: IntroOutroEffectPreset };
    effect_out?: { settings: IntroOutroEffectPreset };
  };
}

@Component({
  selector: "ngx-timeline",
  templateUrl: "./timeline.component.html",
  styleUrl: "./timeline.component.scss",
  standalone: false,
})
export class TimelineComponent implements AfterViewInit, OnDestroy {
  public cellTypeIcon = {
    text: "text",
    media: "photo_landscape_outlined",
    product: "label_outlined",
    bg: "circle_outlined",
    product_asset: "blend_tool",
    undefined: "error_outlined",
  };

  tracks: string[] = [];

  selected: Signal<{ act: TimelineBlock; rel: TimelineBlock }[] | null> =
    computed(() => {
      if (
        (this.selectIdent() == null && this.selectGroupIdent() == null) ||
        this.groupedBlocks() == null
      ) {
        return [];
      }
      const group = this.groupedBlocks().find(
        (group) =>
          group.track === this.selectGroupIdent() ||
          group.blocks.find((block) => block.id === this.selectIdent()) != null,
      );
      const groupBlocksRel = group?.blocks;
      const groupBlocksAct = group?.actBlocks;

      const act =
        this.selectGroupIdent() == null
          ? this.timelineBlocks().filter(
              (block) => block.id === this.selectIdent(),
            )
          : groupBlocksAct;
      const rel =
        this.selectGroupIdent() == null
          ? this.timelineBlocksRel().filter(
              (block) => block.id === this.selectIdent(),
            )
          : groupBlocksRel;

      if (rel == null || act == null) {
        return [];
      }
      return act.map((block, i) => {
        return {
          act: block,
          rel: rel[i],
        };
      });
    });

  cache = {
    element: {
      right: 0,
      left: 0,
      top: 0,
      bottom: 0,
    },
    zoom: {
      top: 0,
      left: 0,
      width: 100,
      height: 100,
    },
    mousePos: {
      x: 0,
      y: 0,
    },
    cursor: {
      rel: 0,
      act: 0,
    },
    selected: [],
    total: 20,
  };

  resizeDirection: string;
  resizeMovement: string;

  cursor = {
    rel_x: 0,
    act_x: 0,
  };

  resizeElement;
  positionInElement = { x: 0, y: 0 };
  grabbing = false;
  moving: WritableSignal<boolean> = signal(false);
  blockMoving: WritableSignal<boolean> = signal(false);
  editing = false;

  showHorizontalScroll = false;
  showVerticalScroll = false;

  timeout;
  groupTimeout;

  unsubscribe$ = new Subject();

  @Input() set timelineSpec(to: TimelineBlockSpec[]) {
    this._timelineSpec.update(() => to);
    const tracks = [...new Set(to.map((block) => block.track))];

    this.tracks = tracks;
    setTimeout(() => {
      this.setElementHeights();
    });
  }
  @Input() maxDuration = 20;
  @Input() set selection(to: string[]) {
    this.resetSelection();

    if (to == null || to.length === 0 || this._timelineSpec().length === 0) {
      return;
    }
    !this.blockMoving() && this.jumpToBlock();

    if (to.length === 1) {
      this.groupedBlocks().find(
        (group) => group.track === to[0] && group.blocks.length > 1,
      )
        ? this.selectGroupIdent.update(() => to[0])
        : this.selectIdent.update(() => to[0]);

      return;
    }
    this.multiSelectIdent.update(() => new Set(to));
  }

  _sub: Subscription;
  @Input() set hoverObservable(to: Observable<CreativeLayer>) {
    if (to == null) {
      return;
    }
    this._sub?.unsubscribe();
    this._sub = to.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (res) => {
        this.isHovered.update(() => (res != null ? res.identifier : null));
      },
    });
  }

  @ViewChild("zoombox") zoomElement: ElementRef;
  @ViewChild("trackview") trackView: ElementRef;
  @ViewChild("track") trackElement: ElementRef;
  @ViewChild("block") blockElement: ElementRef;

  currentBlockElement?: HTMLDivElement;

  @Output() commit = new EventEmitter<TimelineBlockSpec[]>();
  @Output() cursorChange = new EventEmitter<number>();
  @Output() context = new EventEmitter<{ event: any; block: string }>();
  @Output() selectChange = new EventEmitter<{
    id: string;
    clickEvent: MouseEvent;
  }>();
  @Output() effect = new EventEmitter<string>();
  @Output() layerHover = new EventEmitter<string | null>();
  @Output() deselect = new EventEmitter();
  @Output() moveLayerDown = new EventEmitter<string>();
  @Output() moveLayerUp = new EventEmitter<string>();
  @Output() groupLayers = new EventEmitter<{ ids: string[]; dir: string }>();
  @Output() ungroupLayer = new EventEmitter<{ id: string; dir: string }>();
  @Output() settings = new EventEmitter();

  //Actual state
  cursor_time: WritableSignal<number> = signal(0);
  ticksPerSec: WritableSignal<number> = signal(5);
  _timelineSpec: WritableSignal<TimelineBlockSpec[]> = signal([]);
  total: WritableSignal<number> = signal(20);

  //State dependent calculations
  trackLen: Signal<number> = computed(
    () =>
      (100 *
        Math.max(
          ...this._timelineSpec().map((block) => block.duration + block.start),
        )) /
      (this.total() * (this.width() / 100)),
  );
  timelineBlocks: Signal<TimelineBlock[]> = computed(() =>
    this._timelineSpec().map((block) => {
      return {
        top: 0,
        left: (block.start / this.total()) * 100,
        width: (block.duration / this.total()) * 100,
        height: 100,
        track: block.track,
        trackName: block.trackName,
        id: block.id,
        name: block.name,
        type: block.type,
        icon: this.cellTypeIcon[block.type],
        effect: block.effect,
      };
    }),
  );
  timelineBlocksRel: Signal<TimelineBlock[]> = computed(() =>
    (JSON.parse(JSON.stringify(this.timelineBlocks())) as TimelineBlock[]).map(
      (block) => {
        const end = this.actToRel(block.left + block.width);
        const start = this.actToRel(block.left);

        block.left = start;
        block.width = end - start;

        return block;
      },
    ),
  );

  groupedBlocks: Signal<
    {
      blocks: TimelineBlock[];
      actBlocks: TimelineBlock[];
      track: string;
      box: { width: number; height: number; left: number; top: number };
      trackName: string;
    }[]
  > = computed(() =>
    this.tracks.map((track) => {
      const blocks = this.timelineBlocksRel().filter(
        (block) => block.track === track,
      );
      const actBlocks = this.timelineBlocks().filter(
        (block) => block.track === track,
      );
      return {
        track: track,
        trackName: blocks[0].trackName,
        blocks: blocks,
        actBlocks: actBlocks,
        box: this.calcBox(blocks),
      };
    }),
  );

  blocksInViewHorizontal: Signal<TimelineBlock[]> = computed(() =>
    this.timelineBlocksRel().filter(
      (block) => block.left + block.width < 100 || block.left > 0,
    ),
  );

  alignLeft: Signal<boolean> = computed(
    () =>
      this.moving() &&
      (this.resizeMovement === "l" || this.resizeMovement === "m") &&
      this.selected().some((select) => {
        const groupBlocks = this.selectGroupIdent()
          ? this.groupedBlocks()
              .find((group) => group.track === this.selectGroupIdent())
              .blocks.map((block) => block.id)
          : [];
        return this.blocksInViewHorizontal().some(
          (block) =>
            block.id !== select.rel.id &&
            !groupBlocks.includes(block.id) &&
            (block.left + block.width === select.rel.left ||
              block.left === select.rel.left),
        );
      }),
  );
  alignRight: Signal<boolean> = computed(
    () =>
      this.moving() &&
      (this.resizeMovement === "r" || this.resizeMovement === "m") &&
      this.selected().some((select) => {
        const groupBlocks = this.selectGroupIdent()
          ? this.groupedBlocks()
              .find((group) => group.track === this.selectGroupIdent())
              .blocks.map((block) => block.id)
          : [];
        return this.blocksInViewHorizontal().some(
          (block) =>
            block.id !== select.rel.id &&
            !groupBlocks.includes(block.id) &&
            (block.left + block.width === select.rel.left + select.rel.width ||
              block.left === select.rel.left + select.rel.width),
        );
      }),
  );

  seconds: Signal<number> = computed(() => this.total() * (this.width() / 100));
  start: Signal<number> = computed(
    () => this.total() * (this.left() / 100) * this.ticksPerSec(),
  );
  ticks: Signal<number> = computed(() => this.seconds() * this.ticksPerSec());

  ticksInt: Signal<number> = computed(() => Math.floor(this.ticks()));
  startInt: Signal<number> = computed(() => Math.ceil(this.start()));
  secondsInt: Signal<number> = computed(() => Math.floor(this.seconds()));
  tickDist: Signal<number> = computed(() => 100 / this.ticks());

  ticksList: Signal<any> = computed(() => [
    ...Array(this.ticksInt() + 1).keys(),
  ]);
  secondsList: Signal<any> = computed(() => [
    ...Array(this.secondsInt() + 1).keys(),
  ]);

  transformBlock: WritableSignal<{ x: number; y: number }> = signal({
    x: 0,
    y: 0,
  });

  startPadding: Signal<number> = computed(
    () => (this.startInt() - this.start()) * this.tickDist(),
  );
  trackStartPadding: Signal<number> = computed(() => {
    const startSeconds = this.total() * (this.left() / 100);
    return (
      (Math.ceil(startSeconds) - startSeconds) *
      this.tickDist() *
      this.ticksPerSec()
    );
  });

  cursor_act: Signal<number> = computed(
    () => (this.cursor_time() / this.total()) * 100,
  );
  cursor_rel: Signal<number> = computed(() => this.actToRel(this.cursor_act()));
  showCursor: Signal<boolean> = computed(
    () =>
      Math.round(this.left() * 10) / 10 <= this.cursor_act() &&
      Math.round((this.left() + this.width()) * 10) / 10 >= this.cursor_act(),
  );

  top: WritableSignal<number> = signal(0);
  left: WritableSignal<number> = signal(0);
  width: WritableSignal<number> = signal(50);
  height: WritableSignal<number> = signal(100);
  translate: WritableSignal<number> = signal(0);
  multiSelectIdent: WritableSignal<Set<string>> = signal(new Set());
  selectIdent: WritableSignal<string | null> = signal(null);
  selectGroupIdent: WritableSignal<string | null> = signal(null);
  trackBoxHeight: WritableSignal<number> = signal(100);
  viewHeight: WritableSignal<number> = signal(100);
  trackBoxWidth: WritableSignal<number> = signal(100);
  viewWidth: WritableSignal<number> = signal(100);
  isHovered: WritableSignal<string | null> = signal(null);

  isSelected: Signal<Record<string, boolean>> = computed(() => {
    const out = {};

    this.groupedBlocks().forEach((group) => {
      out[group.track] =
        this.multiSelectIdent().has(group.track) ||
        this.selectGroupIdent() === group.track;
      group.blocks.forEach((block) => {
        out[block.id] =
          this.multiSelectIdent().has(block.id) ||
          this.selectIdent() === block.id;
      });
    });

    return out;
  });

  hovered: string;
  ungroupDir: string;

  emptyTracks: Signal<any> = computed(() => {
    const trackFloat = this.viewHeight() / 40 - this.timelineBlocksRel().length; //If you change the size of a block, remember to change 40 to whatever the new size is
    const emptyTracks = Math.floor(trackFloat);
    return Array.from(Array(emptyTracks > 0 ? emptyTracks : 0)).map((i) =>
      i === emptyTracks - 1 ? (1 + trackFloat - emptyTracks) * 40 : 40,
    );
  });

  scrollPos: Signal<{
    top: number;
    height: number;
    left: number;
    width: number;
  }> = computed(() => this.getScrollPos());

  scrolling: boolean = false;
  scrollEnded: any;

  ngAfterViewInit(): void {}

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  calcBox(boxes: TimelineBlock[]) {
    const blockCopies: TimelineBlock[] = JSON.parse(JSON.stringify(boxes));

    const x = Math.min(...blockCopies.map((l) => l.left));
    const y = Math.min(...blockCopies.map((l) => l.top));
    const alt_x = Math.max(...blockCopies.map((l) => l.left + l.width));
    const alt_y = Math.max(...blockCopies.map((l) => l.top + l.height));

    return { left: x, top: y, width: alt_x - x, height: alt_y - y };
  }
  setElementHeights() {
    this.trackBoxHeight.update(
      () => this.trackElement?.nativeElement.offsetHeight ?? 100,
    );
    this.viewHeight.update(
      () => this.trackView?.nativeElement.offsetHeight ?? 100,
    );
    this.trackBoxWidth.update(
      () => this.trackElement?.nativeElement.offsetWidth ?? 100,
    );
    this.viewWidth.update(
      () => this.trackView?.nativeElement.offsetWidth ?? 100,
    );
  }

  checkShowScroll() {
    this.showHorizontalScroll = true;
    if (this.trackBoxHeight() <= this.viewHeight()) {
      return;
    }
    this.showVerticalScroll = true;
  }

  getScrollPos() {
    return {
      height: Math.min((this.viewHeight() / this.trackBoxHeight()) * 100, 100),
      top: (-this.translate() / this.trackBoxHeight()) * 100,
      width: Math.min((this.viewWidth() / this.trackBoxWidth()) * 100, 100),
      left: this.left(),
    };
  }

  actToRel(act) {
    const start = this.left();
    const end = this.width() + this.left();

    return (act - start) / ((end - start) / 100);
  }

  relToAct(rel) {
    const start = this.left();
    const end = this.width() + this.left();

    return rel * ((end - start) / 100) + start;
  }

  snapToTick(position) {
    const tickLength = 100 / (this.total() * this.ticksPerSec());
    const ticksToPos = Math.round(position / tickLength);
    return tickLength * ticksToPos;
  }

  beginResize(direction: string, event: MouseEvent) {
    this.moving.update(() => true);
    if (direction === "m" || direction === "r" || direction === "l") {
      this.grabbing = true;
    }
    if (direction === "rb") {
      this.resizeMovement = "r";
    }
    if (direction === "lb") {
      this.resizeMovement = "l";
    }
    if (direction === "mb") {
      this.resizeMovement = "m";
      this.blockMoving.update(() => true);
    }

    this.resizeElement = event.target as any;

    this.resizeDirection = direction;

    this.positionInElement = {
      x: event.pageX - this.resizeElement.getBoundingClientRect().left,
      y: event.pageY - this.resizeElement.getBoundingClientRect().top,
    };

    this.cache.mousePos = { x: event.clientX, y: event.clientY };

    this.cache.element = this.resizeElement.getBoundingClientRect();
    this.cache.zoom = {
      width: this.width(),
      height: this.height(),
      top: this.top(),
      left: this.left(),
    };
    this.cache.selected = this.selected()?.map((block) => {
      return { ...block.act };
    });
    this.cache.cursor = { act: this.cursor_act(), rel: this.cursor_rel() };
    this.cache.total = this.total();

    window.addEventListener("mousemove", this.resizeMove, false);
    window.addEventListener("mouseup", this.endResize, false);
  }

  _resize_calc(event: MouseEvent, direction: string): { x: number; y: number } {
    const pageDirectionX = event.clientX;

    const directionsX = {
      l: this.cache.element.right,
      r: this.cache.element.left,
      m: this.cache.mousePos.x,
      c: this.cache.mousePos.x,
    };

    const pageDirectionY = event.clientY;

    return {
      x: pageDirectionX - directionsX[direction],
      y: pageDirectionY - this.cache.mousePos.y,
    };
  }

  _move(event: MouseEvent, dir: string) {
    const w = this.zoomElement.nativeElement.offsetWidth;
    const calc = (this._resize_calc(event, dir).x / w) * 100;

    const left = this.cache.zoom.left;
    const width = this.cache.zoom.width;

    if (dir === "r") {
      this.width.update(() => Math.max(Math.min(width + calc, 100 - left), 5));
    }
    if (dir === "l") {
      const oldLeft = left;
      this.left.update(() =>
        Math.max(Math.min(left + calc, left + width - 5), 0),
      );
      this.width.update(() => width + (oldLeft - this.left()));
    }
    if (dir === "m") {
      this.left.update(() => Math.max(Math.min(left + calc, 100 - width), 0));
    }
    if (dir === "c") {
      const oldCursorPos = this.cursor_act();
      this.cursor_time.update(
        () =>
          (Math.max(
            Math.min(
              this.snapToTick(
                this.cache.cursor.act + (calc * this.width()) / 100,
              ),
              this.width() + this.left(),
            ),
            this.left(),
          ) *
            this.total()) /
          100,
      );
      if (this.cursor_act() - oldCursorPos !== 0) {
        this.cursorChange.emit(this.cursor_time());
      }
    }
  }

  _verticalMovement(mousePos, distance = 50) {
    let adjustment = 0;
    let dist = distance;

    const selectID = this.selectIdent() ?? this.selectGroupIdent();
    const index = this.groupedBlocks().findIndex(
      (group) => group.track === selectID,
    );
    const blockGroup = this.groupedBlocks()[index];
    const isGroup = blockGroup?.blocks[0].id !== blockGroup?.track;

    const isGrouped = index === -1;
    const down = mousePos.y >= 0;

    const _update = () => {
      this.transformBlock.update(() => {
        return {
          x: this.transformBlock().x,
          y:
            this.transformBlock().y -
            (down ? 1 : -1) * (adjacentSize + adjustment),
        };
      });
      this.cache.mousePos = {
        x: this.cache.mousePos.x,
        y:
          this.cache.mousePos.y + (down ? 1 : -1) * (adjacentSize + adjustment),
      };
    };

    if (index === (down ? this.groupedBlocks().length - 1 : 0)) {
      return;
    }

    const adjacentBlock = this.groupedBlocks()[index + (down ? 1 : -1)];
    const adjacentIsGroup =
      adjacentBlock?.blocks[0].id !== adjacentBlock?.track;
    const adjacentSize = adjacentIsGroup
      ? adjacentBlock?.blocks.length * 40 + 28
      : 40;
    const blockLeft = Math.min(...this.selected().map((box) => box.rel.left));
    const mouseRelative =
      blockLeft +
      (this.positionInElement.x * 100) /
        this.zoomElement.nativeElement.offsetWidth;
    const adjacentEnd = adjacentBlock?.box.left + adjacentBlock?.box.width;
    const adjacentStart = adjacentBlock?.box.left;

    // //If the block has not been moved enough vertically, don't do anything
    // if (mousePos.y < dist / 1.5 && mousePos.y > -dist / 1.5) {
    //   return;
    // }

    const mouseInBlock =
      mouseRelative > adjacentStart && mouseRelative < adjacentEnd;
    const groupAction =
      !isGrouped && adjacentIsGroup && mouseInBlock && !isGroup;

    if (groupAction) {
      this.groupLayers.emit({
        ids: [adjacentBlock.track, selectID],
        dir: down ? "down" : "up",
      });
      adjustment = down ? -adjacentSize + 24 : -adjacentSize + 4;
      _update();
      return;
    }

    if (!isGrouped && adjacentIsGroup && (!mouseInBlock || isGroup)) {
      dist = (adjacentSize * 3) / 4;
    }

    const group = this.groupedBlocks().find(
      (g) => g.track === this.selected()[0].act.track,
    );
    const blockIndex = group.blocks.findIndex((block) => block.id === selectID);

    const isEndOfGroup = blockIndex === (down ? group.blocks.length - 1 : 0);

    if (isGrouped) {
      adjustment = !isEndOfGroup
        ? -adjacentSize + 40
        : down
          ? -adjacentSize + 4
          : -adjacentSize + 24;
      dist = isEndOfGroup ? 30 : dist;
    }

    if (mousePos.y >= dist) {
      this.moveLayerDown.emit(selectID);
      this.commitTimelinSpec();
      _update();
      return;
    }

    if (mousePos.y <= -dist) {
      this.moveLayerUp.emit(selectID);
      this.commitTimelinSpec();
      _update();
      return;
    }
  }

  _moveBlock(event: MouseEvent, dir: string) {
    const mousePos = this._resize_calc(event, "m");
    const w = this.zoomElement.nativeElement.offsetWidth;
    const calc = (mousePos.x / w) * this.width();

    for (let i = 0; i < this.selected().length; i++) {
      const left =
        (this.cache.selected[i].left / this.total()) * this.cache.total;
      const width =
        (this.cache.selected[i].width / this.total()) * this.cache.total;

      const limit = (this.maxDuration / this.total()) * 100;

      const selected = JSON.parse(JSON.stringify(this.selected()[i])) as {
        act: TimelineBlock;
        rel: TimelineBlock;
      };

      if (dir === "r") {
        selected.act.width = Math.min(
          Math.max(this.snapToTick(width + calc), 20 / this.total()),
          limit - selected.act.left,
        );
      }
      if (dir === "l") {
        const oldLeft = left;
        selected.act.left = Math.max(
          Math.min(
            this.snapToTick(left + calc),
            left + width - 20 / this.total(),
          ),
          0,
        );
        selected.act.width = width + (oldLeft - selected.act.left);
      }
      if (dir === "m") {
        selected.act.left = Math.min(
          Math.max(this.snapToTick(left + calc), 0),
          limit - selected.act.width,
        );

        this.transformBlock.update(() => mousePos);

        this._verticalMovement(mousePos);
      }

      const total = this.total();

      this._timelineSpec.update((blocks) =>
        blocks.map((block) => {
          if (block.id !== selected.act.id) {
            return block;
          }
          return {
            start: Math.round(((selected.act.left * total) / 100) * 10) / 10,
            duration:
              Math.round(((selected.act.width * total) / 100) * 10) / 10,
            id: block.id,
            track: block.track,
            trackName: block.trackName,
            name: block.name,
            type: block.type,
            effect: block.effect,
          };
        }),
      );
      const oldWidth = this.width();
      this.width.update(() =>
        Math.min(
          this.cache.zoom.width * (this.cache.total / this.total()),
          100,
        ),
      );
      this.left.update(() =>
        Math.max(this.left() + (oldWidth - this.width()), 0),
      );

      this.selected()[i].rel = this.timelineBlocksRel().filter(
        (block) => block.id === this.selected()[i].rel.id,
      )[0];
    }
  }

  scroll(event: WheelEvent) {
    this.checkShowScroll();
    this.scrolling = true;
    if (this.scrollEnded != null) {
      clearTimeout(this.scrollEnded);
    }
    this.scrollEnded = setTimeout(() => {
      this.updateBalloon();
    }, 200);
    const deltaY = event.deltaY;
    const deltaX = event.deltaX;
    const speed = 50;

    const trackHeight = this.trackElement.nativeElement.offsetHeight;
    const viewHeight = this.trackView.nativeElement.offsetHeight;

    this.translate.update(() =>
      Math.min(
        Math.max(
          this.translate() - deltaY / (speed / 4),
          -(trackHeight - viewHeight),
        ),
        0,
      ),
    );

    this.left.update(() =>
      Math.min(
        Math.max(this.left() + deltaX / (speed * 2), 0),
        100 - this.width(),
      ),
    );
  }

  resizeMove = (event: MouseEvent) => {
    const func = {
      l: (evnt) => {
        this._move(evnt, "l");
      },
      r: (evnt) => {
        this._move(evnt, "r");
      },
      m: (evnt) => {
        this._move(event, "m");
      },
      c: (evnt) => {
        this._move(event, "c");
      },
      rb: (evnt) => {
        this.editing = true;
        this._moveBlock(event, "r");
      },
      lb: (evnt) => {
        this.editing = true;
        this._moveBlock(event, "l");
      },
      mb: (evnt) => {
        this.editing = true;
        this._moveBlock(event, "m");
      },
    };

    func[this.resizeDirection](event);
  };

  endResize = (event: MouseEvent) => {
    this.grabbing = false;
    this.moving.update(() => false);
    this.blockMoving.update(() => false);
    this.updateBalloon();
    this.resizeMovement = "";
    this.transformBlock.update(() => {
      return { x: 0, y: 0 };
    });
    window.removeEventListener("mousemove", this.resizeMove, false);
    window.removeEventListener("mouseup", this.endResize, false);
    if (this.editing) {
      this.commitTimelinSpec();
      this.editing = false;
    }
    this.layerHover.emit(null);
  };

  commitTimelinSpec() {
    this.commit.emit(this._timelineSpec());
  }

  updateBalloon() {
    delete this.currentBlockElement;
    setTimeout(() => {
      this.scrolling = false;

      const currElement =
        this.trackElement.nativeElement.getElementsByClassName(
          "currentBlock",
        )[0];

      if (currElement == null) {
        return;
      }
      const viewRect = this.trackView.nativeElement.getBoundingClientRect();
      const currRect = currElement.getBoundingClientRect();

      const outOfView =
        viewRect.top > currRect.top || viewRect.bottom < currRect.top;
      if (outOfView) {
        return;
      }

      this.currentBlockElement = currElement;
    }, 20);
  }

  jumpToBlock() {
    setTimeout(() => {
      if (
        this.multiSelectIdent().size > 0 ||
        (this.selectIdent() == null && this.selectGroupIdent() == null)
      ) {
        return;
      }
      const currElement =
        this.selectIdent() != null
          ? this.trackElement.nativeElement.getElementsByClassName(
              "currentBlock",
            )[0]
          : this.trackElement.nativeElement.getElementsByClassName(
              "currentGroup",
            )[0];

      const currRect = currElement.getBoundingClientRect();

      const viewRect = this.trackView.nativeElement.getBoundingClientRect();
      if (currRect.bottom > viewRect.bottom) {
        this.translate.update(
          (translate) => translate - (currRect.bottom - viewRect.bottom),
        );
      }
      if (currRect.top < viewRect.top) {
        this.translate.update(
          (translate) => translate + (viewRect.top - currRect.top),
        );
      }
      this.updateBalloon();
    });
  }

  setSelect(ident: string, event: MouseEvent, type: string) {
    if (event.ctrlKey || event.metaKey) {
      this.multiSelectIdent.update((curr) => {
        const copy = curr;
        copy.has(ident) ? copy.delete(ident) : copy.add(ident);
        return copy;
      });
      this.selectGroupIdent.update(() => null);
      this.selectIdent.update(() => null);
    } else if (type === "group") {
      this.selectGroupIdent.update(() => ident);
      this.selectIdent.update(() => null);
      this.multiSelectIdent.update(() => new Set());
    } else {
      this.selectIdent.update(() => ident);
      this.selectGroupIdent.update(() => null);
      this.multiSelectIdent.update(() => new Set());
    }
    this.selectChange.emit({ id: ident, clickEvent: event });
    setTimeout(() => {
      this.updateBalloon();
    });
  }

  resetSelection() {
    this.selectIdent.update(() => null);
    this.selectGroupIdent.update(() => null);
    this.multiSelectIdent.update(() => new Set());
    this.updateBalloon();
  }

  setCursorTime(time: number, event: MouseEvent) {
    const offset = event.offsetX / (event.target as HTMLDivElement).offsetWidth;
    this.cursor_time.update(() => time + (offset > 0.5 ? 0.2 : 0));
    this.cursorChange.emit(this.cursor_time());
  }
  hover(layer: string | null) {
    this.isHovered.update(() => layer);
    this.layerHover.emit(layer);
  }
}
