import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "ngx-percentage-spec",
  templateUrl: "./percentage-spec.component.html",
  styleUrls: ["./percentage-spec.component.scss"],
  standalone: false,
})
export class PercentageSpecComponent implements OnInit {
  @Input() specItem: any = null;
  @Input() value: any = null;

  @Input() placeholderKey: string = null;

  @Output() valueChange = new EventEmitter<any>();

  pattern = /[-]?[0-9]*\.?[0-9]+$/;
  isValid = true;

  constructor() {}

  ngOnInit() {}

  checkInput(input) {
    const m = input.match(this.pattern);

    if (m === null || m.length === 0) {
      return false;
    }

    if (m[0] === input) {
      return true;
    }

    return false;
  }

  change(to) {
    const v = to.replace(",", ".");
    this.isValid = this.checkInput(v);
    this.valueChange.emit(v);
  }
}
