import { Component, EventEmitter, Input, Output } from "@angular/core";

import { IntroOutroEffectPreset } from "@core/services/creatives/preset-effects";

@Component({
  selector: "ngx-timeline-block",
  templateUrl: "./timeline-block.component.html",
  standalone: false,
})
export class TimelineBlockComponent {
  grabbed = false;

  @Input() current: boolean = false;
  @Input() duration: number = 0;
  @Input() hovered: boolean = false;
  @Input() layerInfo: {
    name: string;
    id: string;
    icon: string;
    effect?: {
      effect_in?: { settings: IntroOutroEffectPreset };
      effect_out?: { settings: IntroOutroEffectPreset };
      effect?: any;
    };
  };
  @Input() set grab(to: boolean) {
    if (this.grabbed === true && to === false) {
      this.layerHover.emit(null);
    }
    this.grabbed = to;
  }
  @Output() resizMove = new EventEmitter<{ dir: string; event: MouseEvent }>();
  @Output() context = new EventEmitter<{ event: any; block: string }>();
  @Output() effect = new EventEmitter<string>();
  @Output() layerHover = new EventEmitter<string | null>();
}
