import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";

import { SharedElementsService } from "@core/api/shared-elements.service";
import {
  SelectedOptionInterface,
  SharedElementInterface,
  SideMenuStructureInterface,
} from "@core/models/shared-elements.types";

import {
  FolderContents,
  FolderInfo,
  FoldersComponent,
  ItemDelegate,
  TypeSettings,
} from "@components/folders";

import { Observable, map, of } from "rxjs";

import { OptionsEnum } from "./options.enum";

@Component({
  selector: "ngx-shared-elements",
  templateUrl: "./shared-elements.component.html",
  styleUrls: ["./shared-elements.component.scss"],
  standalone: false,
})
export class SharedElementsComponent
  implements OnInit, ItemDelegate<SharedElementInterface>
{
  @Input() category: { display: string; key: string };
  @Output() selectedElementEvent = new EventEmitter<SharedElementInterface>();
  @ViewChild("folders") folders: FoldersComponent<SharedElementInterface>;

  public options = OptionsEnum;
  public currentOption: SelectedOptionInterface = {
    category: OptionsEnum.ALL,
    option: "",
  };

  public sideMenuStructure: SideMenuStructureInterface[] = [];
  public loading = false;

  myElements = false;
  settings: TypeSettings = { grid: true, header: true };

  constructor(public sharedElementsService: SharedElementsService) {}

  ngOnInit() {}

  public selectItem(item: SharedElementInterface) {
    this.selectedElementEvent.emit(item);
  }

  loadFolder(
    folder?: FolderInfo,
    options?: any,
  ): Observable<FolderContents<SharedElementInterface>> {
    const Observable = this.sharedElementsService.getElementsByCategoryV2(
      this.category.key,
    );
    return Observable.pipe(
      map((res) => {
        return { items: res, folders: [] };
      }),
    );
  }

  renameItem(item: SharedElementInterface): Observable<boolean> {
    return this.sharedElementsService.updateElement(item, item.name).pipe(
      map((res) => {
        return res.success;
      }),
    );
  }

  getItemImage(item: any) {
    return item.thumbnail;
  }

  duplicateItem(item: SharedElementInterface): Observable<number> {
    return of(1);
  }

  actionsActivated(action: string, item: SharedElementInterface) {}

  deleteItem(item: SharedElementInterface): Observable<boolean> {
    this.loading = true;
    return this.sharedElementsService.deleteElement(item.id).pipe(
      map((res) => {
        this.loading = false;
        return res.success;
      }),
    );
  }

  openItem(item: any) {}

  public isCurrentSelectedCategory(category: string): boolean {
    return this.currentOption.category === category;
  }
}
