import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";

import { DesignsService } from "@core/api/designs.service";
import { SharedElementsService } from "@core/api/shared-elements.service";
import { CreativeLayer } from "@core/models/creative.types";
import { CreativesEditService } from "@core/services/creatives/creatives-edit.service";
import {
  BoundingBox,
  commitLayerBounds,
  iterateSpecificLayers,
  layersBoundingBox,
} from "@core/services/creatives/layout-helpers";
import { UserService } from "@core/services/user.service";

import { CPopupModalService } from "@theme/@confect/services/confect-popup-modal.service";

export enum VerticalAlignment {
  TOP = "top",
  MIDDLE = "middle",
  BOTTOM = "bottom",
  HEIGHT = "height",
}

export enum HorizontalAlignment {
  LEFT = "left",
  CENTER = "center",
  RIGHT = "right",
  WIDTH = "width",
}

@Component({
  selector: "ngx-creatives-multi-select-tools",
  templateUrl: "./creatives-multi-select-tools.component.html",
  styleUrls: ["./creatives-multi-select-tools.component.scss"],
  standalone: false,
})
export class CreativesMultiSelectToolsComponent implements OnInit, OnDestroy {
  @Input() editor: CreativesEditService;
  @Input() bar: boolean;

  @ViewChild("publicElements") publicElementTemp: TemplateRef<any>;

  layers;
  allowSave = false;
  allowGrouping = false;
  groupsLen;
  layerChangeSubscription;
  layerAddRemoveSubscription;

  isAdmin: boolean = false;

  constructor(
    private designService: DesignsService,
    private popUpService: CPopupModalService,
    private elementService: SharedElementsService,
    private us: UserService,
  ) {}

  load() {
    this.allowSave = false;
    this.allowGrouping = false;
    if (this.editor.multiSelectedLayerIdentifiers.size === 0) {
      if (this.editor.layer?.type === "group") {
        this.allowSave = true;
      }
      return;
    }
    this.layers = this.editor.multiSelectedLayers();
    this.allowSave = this.layers.every(
      (layer) => layer.parent == null && layer.type !== "group",
    );
    this.groupsLen = this.layers.filter((layer) => {
      return layer.type === "group";
    }).length;
    const group = this.layers.find((layer) => layer.type === "group");
    this.allowGrouping = !(
      this.groupsLen > 1 ||
      (group != null &&
        this.layers.some((layer) => layer.parent === group.identifier))
    );
  }

  ngOnInit(): void {
    this.us.me(false).subscribe({
      next: (me) => {
        this.isAdmin = this.us.checkUserHasPermission(me, "admin");
      },
    });
    this.load();
    this.layerChangeSubscription = this.editor.layerChanged$.subscribe({
      next: (res) => {
        this.load();
      },
    });
    this.layerAddRemoveSubscription = this.editor.layerAddRemove$.subscribe({
      next: (res) => {
        this.load();
      },
    });
  }

  ngOnDestroy(): void {
    this.layerChangeSubscription?.unsubscribe();
    this.layerChangeSubscription = null;

    this.layerAddRemoveSubscription?.unsubscribe();
    this.layerAddRemoveSubscription = null;
  }

  align(
    layers: CreativeLayer[],
    vAlignment?: VerticalAlignment,
    hAlignment?: HorizontalAlignment,
  ) {
    if (!vAlignment && !hAlignment) {
      return;
    }

    // All inner layers / no groups
    const _layers = Array.from(iterateSpecificLayers(layers));

    let maxWidth = -1;
    let maxHeight = -1;

    let maxWidthLayerBB: BoundingBox = null;
    let maxHeightLayerBB: BoundingBox = null;

    for (const layer of _layers) {
      const bb = layersBoundingBox(layer);
      if (bb.width > maxWidth) {
        maxWidth = bb.width;
        maxWidthLayerBB = bb;
      }

      if (bb.height > maxHeight) {
        maxHeight = bb.height;
        maxHeightLayerBB = bb;
      }
    }

    const bb = layersBoundingBox(..._layers);
    _layers.forEach((layer) => {
      const currentBB = layersBoundingBox(layer);

      switch (vAlignment) {
        case VerticalAlignment.TOP:
          currentBB.top = bb.top;
          break;
        case VerticalAlignment.MIDDLE:
          currentBB.top = bb.top + bb.height / 2 - currentBB.height / 2;
          break;
        case VerticalAlignment.BOTTOM:
          currentBB.top = bb.top + bb.height - currentBB.height;
          break;
        case VerticalAlignment.HEIGHT:
          currentBB.top = maxHeightLayerBB.top;
          currentBB.height = maxHeightLayerBB.height;
          break;
      }

      switch (hAlignment) {
        case HorizontalAlignment.LEFT:
          currentBB.left = bb.left;
          break;
        case HorizontalAlignment.CENTER:
          currentBB.left = bb.left + bb.width / 2 - currentBB.width / 2;
          break;
        case HorizontalAlignment.RIGHT:
          currentBB.left = bb.left + bb.width - currentBB.width;
          break;
        case HorizontalAlignment.WIDTH:
          currentBB.left = maxWidthLayerBB.left;
          currentBB.width = maxWidthLayerBB.width;
          break;
      }

      commitLayerBounds(layer, currentBB);
      this.editor.layerChangedSource.next(true);
    });
  }

  verticalAlign(alignment: string | VerticalAlignment) {
    const layers = this.editor.layer
      ? [this.editor.layer]
      : this.editor.multiSelectedLayers();

    this.editor.history.beginSpecSnapshot();
    this.align(layers, alignment as VerticalAlignment);
    this.editor.history.finishSpecSnapshot();
  }

  horizontalAlign(alignment: string | HorizontalAlignment) {
    const layers = this.editor.layer
      ? [this.editor.layer]
      : this.editor.multiSelectedLayers();

    this.editor.history.beginSpecSnapshot();
    this.align(layers, null, alignment as HorizontalAlignment);
    this.editor.history.finishSpecSnapshot();
  }

  saveElement() {
    if (!this.allowSave) {
      return;
    }
    const layerGroup =
      this.editor.multiSelectedLayerIdentifiers.size > 0
        ? this.editor.groupLayers(this.editor.multiSelectedLayers())
        : JSON.parse(JSON.stringify(this.editor.layer));

    this.elementService.saveLayerGroup(
      layerGroup,
      this.designService,
      this.popUpService,
    );
  }

  savePublicElement() {
    if (!this.allowSave) {
      return;
    }
    const layerGroup =
      this.editor.multiSelectedLayerIdentifiers.size > 0
        ? this.editor.groupLayers(this.editor.multiSelectedLayers())
        : JSON.parse(JSON.stringify(this.editor.layer));

    this.elementService.getElementCategories().subscribe({
      next: (res) => {
        this.popUpService
          .template({
            template: this.publicElementTemp,
            showClose: false,
            extra: {
              categories: res,
              element: { spec: layerGroup, categories: [], name: "" },
            },
          })
          .outputs["modalClosed"].asObservable()
          .subscribe({
            next: (res) => {
              if (!res) {
                return;
              }
              this.elementService.createPublicElement(res).subscribe({
                next: (res) => {
                  this.popUpService.success({});
                },
                error: (res) => {
                  this.popUpService.error({});
                },
              });
            },
          });
      },
    });
  }
  groupLayers() {
    switch (this.groupsLen) {
      case 0:
        this.editor.groupSelectedLayers();
        break;
      case 1:
        const group = this.layers.filter((layer) => layer.type === "group");
        this.editor.history.beginSpecSnapshot();
        this.layers.forEach((layer) => {
          if (layer.type !== "group") {
            this.editor.addToGroup(layer, group[0]);
          }
        });
        this.editor.deselectLayer();
        this.editor.selectLayer(group[0].identifier);
        this.editor.history.finishSpecSnapshot();
        break;
      default:
        return;
    }
  }

  ungroupLayers(groupID: string) {
    this.editor.desolveGroup(groupID);
  }
}
