import {
  Component,
  ComponentRef,
  Type,
  ViewChild,
  ViewContainerRef,
  OnInit,
  OnDestroy,
} from "@angular/core";

import { PopUpEditorService } from "@theme/@confect/services/confect-pop-up-editor.service";

import { Subject, first, takeUntil } from "rxjs";

import { CreativesEditPopUpEditorComponent } from "../creatives-edit-pop-up-editor.component";

@Component({
  selector: "confect-pop-up-editor-outlet",
  templateUrl: "./creatives-edit-pop-up-editor-outlet.component.html",
  standalone: false,
})
export class CreativesEditPopUpEditorOutletComponent
  implements OnInit, OnDestroy
{
  destroy$ = new Subject();

  /** The target point in template where all modal-components are bing inserted. */
  @ViewChild("editorOutlet", { read: ViewContainerRef, static: true })
  editorContainerRef: ViewContainerRef;

  constructor(private popUpEditorService: PopUpEditorService) {}

  /** Destroys the `content` and the `modal` itself. */
  private static destroyContent(content: ComponentRef<any>): void {
    content.destroy();
  }

  ngOnInit(): void {
    this.handleNewModal();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  /** Listens to `modalService.modalObservable` and loads/appends the modal-component. */
  private handleNewModal(): void {
    this.popUpEditorService.editorObservable
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (opts) => {
          opts.event?.preventDefault();
          const editorComponent =
            this.loadComponent<CreativesEditPopUpEditorComponent>(
              CreativesEditPopUpEditorComponent,
              this.editorContainerRef,
              {
                id: opts.id,
              },
            );

          opts.editorRef.close = () => editorComponent.instance.close();
          opts.editorRef.afterClose = editorComponent.instance.afterClose;
          editorComponent.instance.afterClose.pipe(first()).subscribe({
            next: () => {
              CreativesEditPopUpEditorOutletComponent.destroyContent(
                editorComponent,
              );
            },
          });
        },
      });
  }
  /** Loads the `component` into `parentContainerRef`. */
  private loadComponent<C>(
    component: Type<any>,
    parentContainerRef: ViewContainerRef = this.editorContainerRef,
    inputs = {},
    outputs?,
  ): ComponentRef<C> {
    const componentRef = parentContainerRef.createComponent(component);

    // Pass all @Input bindings
    Object.keys(inputs).forEach((input) => {
      componentRef.setInput(input, inputs[input]);
    });

    // Pass all @Output bindings
    if (outputs) {
      Object.keys(outputs).forEach((output) => {
        outputs[output] = componentRef.instance[output];
      });
    }

    return componentRef as ComponentRef<C>;
  }
}
