import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from "@angular/core";

import { CreativesEditService } from "@core/services/creatives/creatives-edit.service";

import { SpecChangeEvent } from "../../spec-handler/spec-handler.component";

@Component({
  selector: "ngx-effects-spec",
  templateUrl: "./effects-spec.component.html",
  styleUrls: ["./effects-spec.component.scss"],
  standalone: false,
})
export class EffectsSpecComponent implements OnInit, OnChanges {
  _specItem: any = null;
  limitedEffects: any[] = [];
  effectSpecs: any = null;

  get specItem(): any {
    return this._specItem;
  }

  @Input()
  set specItem(specItem: any) {
    this._specItem = specItem;

    // This breaks undo/redo, we need to monitor if default effect selection
    // Support for effects with a default selection
    this.checkForDefault();
  }

  @Input() value: any = null;

  @Input() editor: CreativesEditService;

  @Input() helpEnabled = true;

  @Output() valueChange = new EventEmitter<SpecChangeEvent>();

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      this.checkForDefault();
    }
  }

  ngOnInit() {}

  checkForDefault() {
    setTimeout(() => {
      // If has a default selection, but no settings yet, make sure the object is set
      if (
        this.specItem.default.effect !== null &&
        !Object.keys(this.value.settings).length &&
        this.value.effect === this.specItem.default.effect // Only if saved effect is default as well
      ) {
        this.effectChanged(this.specItem.default.effect, false);
      }

      this.updateEffectSpecs();
      this.limitedEffects = this.calculateLimitedEffects();
    }, 100);
  }

  calculateLimitedEffects() {
    if (!this._specItem) {
      return [];
    }

    const effects = this._specItem.data;

    if (this._specItem.name === "effect_in") {
      return effects.filter((e) => e.function === "intro");
    } else if (this._specItem.name === "effect_out") {
      return effects.filter((e) => e.function === "outro");
    }
    return effects;
  }

  updateEffectSpecs() {
    if (!this._specItem) {
      this.effectSpecs = null;
      return;
    }
    const n = this.value.effect;
    this.effectSpecs = this._specItem.data.find((s) => s.function === n)?.spec;
  }

  effectChanged(event, snapshot = true) {
    this.valueChange.emit({
      newValue: event,
      shouldSnapshot: snapshot,
    });

    this.value.settings = {};

    setTimeout(() => {
      this.updateEffectSpecs();
    });
  }
}
