import { Component, EventEmitter, Input, Output } from "@angular/core";

import { SPEC } from "../spec";

@Component({
  selector: "ngx-effect-edit",
  templateUrl: "./effect-edit.component.html",
  standalone: false,
})
export class EffectEditComponent {
  _effect: any;
  @Input() set effect(to: any) {
    this._effect = to;

    if (to == null) {
      return;
    }
    this.setDefaults(this._effect);
  }
  @Input() maxDuration: number;

  @Output() remove = new EventEmitter();
  @Output() presetChange = new EventEmitter();
  @Output() change = new EventEmitter();

  spec = {};

  setDefaults(effect) {
    if (effect.effect === "") {
      return;
    }
    SPEC.filter((spec) => spec.function === effect.effect)[0].spec.forEach(
      (type) => {
        effect.settings[type.name] = effect.settings[type.name] ?? type.default;
      },
    );
  }

  setDirection(dir) {
    this.changed();
    if (this._effect.settings["slide_in_direction"] === dir) {
      delete this._effect.settings["slide_in_direction"];
      return;
    }
    this._effect.settings["slide_in_direction"] = dir;
  }

  changed() {
    this.change.emit();
    delete this._effect.preset;
  }

  parseInt(str) {
    return parseInt(str);
  }

  constructor() {
    SPEC.forEach((spec) => {
      this.spec[spec.function] = { name: spec.name, spec: spec.spec };
    });
  }
}
