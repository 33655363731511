import { Component, OnInit } from "@angular/core";

import { DesignTemplate } from "@core/api-models/designs.models";
import { Product } from "@core/api-models/products.models";
import { DesignsService } from "@core/api/designs.service";
import { ProductsService } from "@core/api/products.service";
import { AccessService } from "@core/services/access.service";

import { forkJoin } from "rxjs";

@Component({
  selector: "ngx-creatives-demo",
  templateUrl: "./creatives-demo.component.html",
  standalone: false,
})
export class CreativesDemoComponent implements OnInit {
  step = 0;
  products: Product[] = [];
  templates: DesignTemplate[] = [];

  constructor(
    public as: AccessService,
    private ps: ProductsService,
    private ds: DesignsService,
  ) {}

  ngOnInit(): void {
    forkJoin({
      prods: this.ps.searchProducts({ text: null, page_size: 4 }),
      templateSections: this.ds.getDesignTemplates(),
    }).subscribe({
      next: (resp) => {
        this.products = resp.prods.data;
        this.templates = resp.templateSections[0].items.slice(undefined, 4);
      },
    });
  }
}
