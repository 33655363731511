import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from "@angular/core";

import { CreativesEditService } from "@core/services/creatives/creatives-edit.service";

import { Subscription } from "rxjs";

@Component({
  selector: "ngx-product-subscription-spec",
  templateUrl: "./product-subscription-spec.component.html",
  styleUrls: ["./product-subscription-spec.component.scss"],
  standalone: false,
})
export class ProductSubscriptionSpecComponent implements OnInit, OnDestroy {
  public cellTypeIcon = {
    text: "text", // text-outline
    media: "photo_landscape_outlined", // image-outline
    product: "label_outlined", // pricetags-outline
    bg: "square_outlined", // square-outline
    product_asset: "add", // pantone-outline
    undefined: "add", // loader-outline
  };

  @Input() specItem: any = null;
  @Input() value: any = null;

  @Output() valueChange = new EventEmitter<any>();
  @Output() resetClicked = new EventEmitter<any>();

  _editor: CreativesEditService;
  @Input() set editor(to: CreativesEditService) {
    this._editor = to;
    this.subscribeLayers();
  }

  layerSubscriptions: Subscription[] = [];
  layers = [];

  @Input() targetType = "product";

  constructor() {}

  ngOnInit() {}

  ngOnDestroy() {
    this.unsubscribeLayers();
  }

  subscribeLayers() {
    this.unsubscribeLayers();

    if (!this._editor) {
      this.layers = [];
      return;
    }

    const handler = (_) => {
      this.layers = Array.from(this._editor.iterateLayers(false));
    };

    this.layerSubscriptions = [
      this._editor.layerChanged$.subscribe(handler),
      this._editor.layerAddRemove$.subscribe(handler),
    ];

    handler(null);
  }

  unsubscribeLayers() {
    this.layerSubscriptions.forEach((ls) => ls.unsubscribe());
    this.layerSubscriptions = [];
  }

  getWeightedSize(single, total) {
    let summedTotal = 0;
    for (const x of Object.keys(total)) {
      summedTotal += parseFloat(total[x]);
    }

    return (single / summedTotal) * 100 + "%";
  }

  getCellInLayer(layer, row, col, create = false) {
    // Check if current cell exists, otherwise create
    const gc = layer.config.grid_config;

    const c = gc.filter((cell) => cell.pos[0] === row && cell.pos[1] === col);

    if (c.length === 0) {
      return null;
    }

    // Return cell
    return c[0];
  }

  layerClicked(layer) {
    const gc = layer.config.grid_config[0];
    if (gc == null) {
      return;
    }
    if (gc.type !== this.targetType) {
      return;
    }
    this.valueChange.emit([this.specItem, layer, 1, 1]);
  }
}
