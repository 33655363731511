<div
  class="z-[1000] fixed flex items-center justify-center h-[100vh] w-[100vw] top-0 left-0"
>
  <div class="w-full h-full bg-gray-500 opacity-50 absolute"></div>
  <div class="xl:w-[70vw] xl:h-[70vh] w-[90vw] h-[90vh] z-[1050]">
    <div class="h-full w-full rounded-md shadow-md bg-white px-8 py-5">
      <div class="grid grid-cols-2 h-full w-full divide-x-2">
        @if (step === 0) {
          <div
            class="p-8 text-center flex flex-col space-y-8 items-center justify-center"
          >
            <div class="text-lg font-semibold text-center">
              With Confect you can customize your
              <span class="whitespace-nowrap">Catalog Ads</span>
            </div>
            <img class="" src="/assets/images/empty-states/designs.png" />
            <confect-button (click)="checkForTemplate()" size="xxlarge"
              >Let's try</confect-button
            >
          </div>
        }
        @if (step === 1) {
          <div class="flex flex-col h-full w-full grow-0 items-center">
            <div class="text-lg font-semibold h-6 text-center my-4">
              Choose an element to add to your design
            </div>
            <div class="w-full h-rest">
              <div class="w-full h-full overflow-auto">
                <div class="w-full pl-16 pr-24 mt-8">
                  <ngx-creatives-elements-sidebar
                    [filter]="categoryFilter"
                    [editor]="editor"
                    (elementPicked)="renderDesign()"
                  />
                </div>
              </div>
            </div>
          </div>
        }

        @if (step === 2) {
          <div class="flex flex-col justify-center items-center">
            <confect-loading [loading]="true" />
          </div>
        }

        @if (step === 3) {
          <div
            class="p-8 text-center flex flex-col space-y-8 items-center justify-center"
          >
            <h4>Looking Good!</h4>
            <p class="text-lg">
              When you have your first design, you can use it on Facebook,
              Klaviyo, and more
            </p>
            <confect-button (click)="step = 4" size="xxlarge"
              >Continue</confect-button
            >
          </div>
        }

        @if (step === 4) {
          <div
            class="p-8 text-center flex flex-col space-y-8 items-center justify-center"
          >
            <h4>You are now ready to design!</h4>
            <confect-button (click)="done.emit($event)" size="xxlarge"
              >Start using the editor</confect-button
            >
          </div>
        }
        <div class="col-span-1 pl-8">
          <div class="w-full h-full flex items-center">
            <div
              class="w-full flex flex-col justify-between 3xl:px-8 xl:px-5 px-3 3xl:py-12 xl:py-8 py-5 rounded-2xl shadow-lg ring-inset ring-2 ring-gray-200"
            >
              <div class="flex items-center justify-start space-x-8">
                <img
                  class="block h-16 w-auto"
                  src="/assets/images/logo-notext.svg"
                />
                <div class="flex flex-col 3xl:space-y-4 space-y-2 w-full">
                  <div class="w-1/3 3xl:h-3 h-2 rounded-full bg-gray-200"></div>
                  <div class="w-1/4 3xl:h-3 h-2 rounded-full bg-gray-200"></div>
                </div>
              </div>
              <div class="flex flex-col 3xl:space-y-4 space-y-2 my-4">
                <div class="w-2/3 3xl:h-3 h-2 rounded-full bg-gray-200"></div>
                <div class="w-1/2 3xl:h-3 h-2 rounded-full bg-gray-200"></div>
              </div>
              <div
                class="flex w-full overflow-x-auto gap-x-2 p-1"
                [style.scrollbar-width]="'none'"
              >
                @for (product of products; track product.id) {
                  <div
                    class="w-1/3 flex shrink-0 grow-0 flex-col rounded-2xl ring-2 ring-slate-100 bg-slate-100"
                  >
                    <div
                      class="h-full rounded-2xl transition-opacity duration-300 overflow-hidden"
                    >
                      <img
                        class="aspect-square object-contain h-full w-full bg-white"
                        [src]="product.cdn_image"
                        [ngClass]="{ 'opacity-0': hideProducts }"
                      />
                    </div>
                    <div class="flex flex-col p-4 space-y-2">
                      <!-- <span>{{ product.name }}</span>
                          <span class="text-gray-400">{{
                            product.price | currency: product.currency
                          }}</span> -->

                      <div
                        class="w-1/2 3xl:h-3 h-2 rounded-full bg-gray-200"
                      ></div>
                      <div
                        class="w-1/3 3xl:h-3 h-2 rounded-full bg-gray-200"
                      ></div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
