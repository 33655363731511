import { Location } from "@angular/common";
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  OnInit,
  OnDestroy,
  AfterViewInit,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { DesignsService } from "@core/api/designs.service";
import {
  Creative,
  CreativeEditorMode,
  CreativePreviewSettings,
} from "@core/models/creative.types";
import { AccessService } from "@core/services/access.service";
import { CreativesEditService } from "@core/services/creatives/creatives-edit.service";
import {
  CreativeLiveServiceConfig,
  CreativesLiveService,
} from "@core/services/creatives/creatives-live.service";
import { CustomFieldsService } from "@core/services/custom-fields.service";
import { HomeActionsService } from "@core/services/home-actions-service";
import { HotjarService } from "@core/services/hotjar.service";
import { ProctorService } from "@core/services/proctor.service";
import { UserService } from "@core/services/user.service";

import { CPopupModalService } from "@theme/@confect/services/confect-popup-modal.service";

import { Observable, Subject, takeUntil } from "rxjs";

import { resolutions } from "../../creatives-resolutions";
import { CreativeError } from "../../types/creative-error";
import { CreativesEditEditorComponent } from "../creatives-edit-editor/creatives-edit-editor.component";

@Component({
  selector: "ngx-creatives-edit-pop-up-editor",
  templateUrl: "./creatives-edit-pop-up-editor.component.html",
  styleUrls: ["./creatives-edit-pop-up-editor.component.scss"],
  standalone: false,
})
export class CreativesEditPopUpEditorComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  public readonly CreativeEditorMode = CreativeEditorMode;
  unsubscribe$ = new Subject();

  selectedPreviewFilters = {};
  previewFilterOptions = [];
  editorMode: CreativeEditorMode = CreativeEditorMode.IMAGE;
  selectedResolution = "1:1";
  resolutions = resolutions;
  introFinished: boolean = false;
  raisedError: CreativeError = null;

  // Id of creative we are editing
  @Input() id = null;

  @Output() afterClose = new EventEmitter<any>();

  videoSupport = false;

  hasUnsavedChanges = false;

  // Extra settings
  extraSettings = { minimalUser: true };
  helpEnabled = true;

  // Recipe callback when we wish to go to recipes after save
  recipeCreateCallback = null;

  // Creative spec editor instance
  editor: CreativesEditService = null;
  live: CreativesLiveService = null;

  // Sidebar content
  specs: any = {};

  demoMode = false;
  demoModePreviewClicked = false;

  // Video loading / preview
  currentProgress = 0;
  videoURL = null;

  isShown: boolean = false;

  @ViewChild("editorComponent") editorRef: CreativesEditEditorComponent;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private designsService: DesignsService,
    private proctor: ProctorService,
    private accessService: AccessService,
    private ref: ChangeDetectorRef,
    private fs: CustomFieldsService,
    private us: UserService,
    private ps: CPopupModalService,
    private ha: HomeActionsService,
    private hj: HotjarService,
  ) {
    this.introFinished = this.accessService.current?.account.state.has_designs;
  }

  // Init
  ngOnInit() {
    this.initializeEditor(this.id);

    this.us.me(false).subscribe((me) => {
      this.demoMode = me.anon === true;
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isShown = true;
    }, 50);
  }

  ngOnDestroy() {
    this.live?.destroy();
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  loadEditorService(creative: Creative = null) {
    const ps: CreativePreviewSettings = creative
      ? creative.preview_settings
      : {};

    // Saved preview filters
    const f = ps.preview_filters;
    this.selectedPreviewFilters = f ? f : {};

    // Optionally get stuff from state?

    const state = window.history.state;
    const routerSpec = state?.spec ?? null;
    const resolutionState = state?.resolution ?? null;

    // Saved editor mode
    const editorMode = ps.editorMode;
    if (editorMode) {
      this.editorMode = editorMode;
    }

    // Saved resolution/aspect
    let selRes = ps.selectedResolution;
    let res = ps.resolution;

    if (!creative && resolutionState) {
      selRes = "custom";
      res = resolutionState;
    }

    if (selRes && res) {
      this.selectedResolution = selRes;

      if (selRes === "custom") {
        this.resolutions["custom"].size = res;
      }

      setTimeout(() => {
        this.editorRef.configureResolution();
      });
    }

    this.hj.event(
      this.editorMode === CreativeEditorMode.VIDEO
        ? "video_editor_loaded"
        : "image_editor_loaded",
    );

    // Live images cache
    const cachedImages = creative ? creative.live_image_cache : {};

    const spec = creative?.spec ?? routerSpec;

    this.editor = new CreativesEditService(
      spec,
      creative?.name,
      this.accessService.current.account.has_feed,
    );

    const company = this.accessService.current.company.id;
    const account = this.accessService.current.account.id;

    // We need to configure our live service
    const liveConfig: CreativeLiveServiceConfig = {
      editor: this.editor,
      company: company,
      account: account,
      proctor: this.proctor.info.proctorModeActive()
        ? this.proctor.info.currentProctorCompany.id
        : null,
      previewFilters: this.selectedPreviewFilters,
      resolution: this.editorRef?.currentLiveResolution ?? [500, 500],
    };

    // Activate live mode for the editor
    this.live = this.editor.activateLiveMode(liveConfig);
    this.live.checkLayers();

    // Load cached images
    if (cachedImages) {
      this.live.loadInitialImages(cachedImages);
    }

    this.setupCallbacks();
  }

  initializeEditor(id: number) {
    this.loadEditorSettings();
    this.loadPreviewFilterOptions();

    // Feature enables
    if (this.accessService.hasFeature("video")) {
      this.extraSettings.minimalUser = false;
      this.videoSupport = true;
      // this.editorMode = "video";
    }

    // Video spec load
    this.designsService
      .getVideoSpecs()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((specs) => {
        this.specs = specs;
      });

    // Load creative from API
    this.id = id;
    this.designsService
      .getCreatives(this.id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (res) => {
          this.loadEditorService(res);
          // this.refreshLayerPreview();
        },
        error: (_err) => {
          this.router.navigate(["/designs"]);
        },
      });
  }

  setupCallbacks() {
    this.editor.layerChanged$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((layer) => {
        // Do a change detection on layer change, to make sure all settings and defaults sync
        this.ref.detectChanges();
      });

    // Live on backend raises error
    this.live.errorRaised$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((error) => {
        this.raisedError = error;
      });

    this.editor.history.createdHistoryPoint$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((_) => {
        this.hasUnsavedChanges = true;
      });

    // Make sure we detect initial cell
    // this.cellChanged(this.editor.cell);
  }

  // Check if we have a recipe callback as query param and set it
  checkRecipeCallback() {
    const qpm = this.route.snapshot.queryParamMap;
    if (qpm.has("recipeCallback")) {
      this.recipeCreateCallback = qpm.get("recipeCallback");
    }
  }

  // Load user-specific editor settings
  loadEditorSettings() {
    const h = localStorage["editorHelpEnabled"];
    if (h === undefined) {
      return;
    }
    this.helpEnabled = h === "true";
  }
  // Product preview filters
  loadPreviewFilterOptions() {
    this.fs
      .getFields()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.previewFilterOptions = res;
      });
  }

  freeTrial() {
    window.open("https://confect.io/start-free-trial/", "_blank");
  }

  saveWarning() {
    this.ps
      .warning({
        title: "This design is used in other places.",
        text: "If you save, any changes made will be applied globally. Duplicate the design to only apply the changes for this instance.",
        confirmText: "Duplicate Design",
        cancelText: "Save Design",
      })
      .outputs["modalClosed"].asObservable()
      .subscribe({
        next: (res) => {
          if (res) {
            this.ps
              .input({
                title: "Choose new name",
                text: "Set a name for the changed design, so you can tell them apart.",
                value: JSON.parse(JSON.stringify(this.editor.name)),
              })
              .outputs["modalClosed"].asObservable()
              .subscribe({
                next: (name) => {
                  this.designsService.duplicateCreative(this.id).subscribe({
                    next: (res) => {
                      this.save(false, { name: name, id: res.id });
                    },
                  });
                },
              });
          } else {
            this.save();
          }
        },
      });
  }

  // Save creative
  async save(callback = false, duplicate: { name: string; id: number } = null) {
    if (this.editor.name === "") {
      return;
    }

    const spec = this.editor.getCleanSpec();

    const creative: Creative = {
      name: duplicate?.name ?? this.editor.name,
      spec: spec,
      preview_settings: {
        preview_filters: this.selectedPreviewFilters,
        selectedResolution: this.selectedResolution,
        resolution: this.editorRef.currentPreviewResolution,
        editorMode: this.editorMode,
      },
    };

    if (this.id !== null) {
      creative["id"] = duplicate?.id ?? this.id;
    }

    this.designsService
      .saveCreative(creative)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (res) => {
          // Triggering a home update here, should be implemented later as a global event service
          this.ha.getData(true).subscribe();

          let created = false;
          if (this.id === null) {
            created = true;
            const url = this.router
              .createUrlTree([res["id"]], { relativeTo: this.route })
              .toString();

            this.location.replaceState(url);
            this.id = res["id"];
          }

          if (callback && this.recipeCreateCallback !== null) {
            this.router.navigate(
              ["automations", "create", this.recipeCreateCallback],
              { queryParams: { selectCreativeId: this.id } },
            );
          } else {
            this.hasUnsavedChanges = false;

            this.ps
              .success({
                title: "Saved!",
                autoCloseTimeout: 1500,
              })
              .outputs["modalClosed"].asObservable()
              .subscribe({
                next: (res) => {
                  this.afterClose.emit(duplicate?.id);
                },
              });
            setTimeout(() => {
              this.validateVideo();
            }, 1500);
          }
        },
        error: (err) => {},
        complete: () => {},
      });
  }

  // Observable for rendering a video job with progres
  renderJobObservable(jobID) {
    return new Observable((observer) => {
      const action = setInterval(() => {
        this.designsService
          .getVideoStatus(jobID)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((res) => {
            this.currentProgress = res.progress;
            if (res.status === "finished") {
              observer.next(res.result.url);
              clearInterval(action);
              observer.complete();
            } else if (res.status === "failed") {
              observer.error(res.error);
              clearInterval(action);
              observer.complete();
            }
          });
      }, 1000);
    });
  }

  validateVideo() {
    this.raisedError = null;

    const spec = this.editor.getSpec();
    const size = [50, 50];

    this.designsService
      .enqueueVideoDemo(spec, size, false, this.selectedPreviewFilters)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res1) => {
        this.renderJobObservable(res1.job_id)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe({
            next: (_) => {},
            error: (error) => {
              this.raisedError = error;

              this.ps.error({
                title: "Saved with error",
                text: error.message,
                autoCloseTimeout: 4000,
              });
            },
          });
      });
  }

  closeWarning() {
    if (!this.hasUnsavedChanges) {
      this.afterClose.emit();
      return;
    }

    this.ps
      .warning({
        title: "You have unsaved changes",
        confirmText: "Close Anyway",
        cancelText: "Keep Editing",
      })
      .outputs["modalClosed"].asObservable()
      .subscribe({
        next: (res) => {
          if (res) {
            this.afterClose.emit();
          }
        },
      });
  }

  close = () => {
    this.afterClose.emit();
  };
}
