import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { DesignsService } from "@core/api/designs.service";

@Component({
  selector: "ngx-color-spec",
  templateUrl: "./color-spec.component.html",
  styleUrls: ["./color-spec.component.scss"],
  standalone: false,
})
export class ColorSpecComponent implements OnInit {
  @Input() specItem: any = null;

  currentColor = null;
  private _value: any = null;

  get value(): any {
    return this._value;
  }

  @Input()
  set value(to) {
    this.currentColor = this.designsService.outputColor(to);
    this._value = to;
  }

  presetColors: any = [];
  @Output() valueChange = new EventEmitter<any>();

  constructor(private designsService: DesignsService) {}

  ngOnInit() {}

  colorPickerChange(value) {
    this.valueChange.emit(this.designsService.parseColor(value));
  }
}
